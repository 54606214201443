<footer id="home-footer" class="home-footer">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-6 col-12">
        <div class="footer-col1">
          <p>
            <img
              src="https://fmarket.vn/trade/assets/images/homepage/footer-icon-fmarket.svg"
              alt="Fmarket"
              loading="lazy"
              width="100"
              height="21"
            />
          </p>
          <p>© 2018 FINCORP JSC. All rights reserved.</p>
        </div>
      </div>
      <div class="col-lg-2 col-md-6 col-12 isDesktop">
        <ul class="footer-link">
          <li class="nav-item">
            <a [href]="Constant.url_fund_home" target="_blank"> Thị trường </a>
          </li>
          <li class="nav-item">
            <a [href]="Constant.url_our_story" target="_blank">
              Về chúng tôi
            </a>
          </li>

          <li class="nav-item">
            <a [href]="Constant.url_contact" target="_blank"> Liên hệ</a>
          </li>
        </ul>
      </div>
      <div class="col-lg-2 col-md-6 col-12 isDesktop">
        <ul class="footer-link">
          <li class="nav-item">
            <a [href]="Constant.url_fmarketPlatform" target="_blank">
              Fmarket Platform
            </a>
          </li>
          <li class="nav-item">
            <a [href]="Constant.url_legal_term" target="_blank">
              Điều khoản sử dụng
            </a>
          </li>
          <li class="nav-item">
            <a [href]="Constant.url_legal_privacy" target="_blank">
              Quyền riêng tư</a
            >
          </li>
          <li class="nav-item">
            <a [href]="Constant.url_our_partner" target="_blank"> Đối tác</a>
          </li>
        </ul>
      </div>
      <div class="col-lg-4 col-md-6 col-12">
        <ul class="footer-support isDesktop">
          <li>
            <a class="btn-1" href="mailto:hello@fmarket.vn" target="_top">
              hello&#64;fmarket.vn
            </a>
          </li>
          <li>
            <a href="tel:1900571299">
              {{ "Hỗ trợ" }}:
              <b>1900 571 299</b>
              (1000đ/phút)
            </a>
          </li>
          <li>
            <img src="../../../../assets/images/icon/ic-facebook.svg" alt="" />
            <a
              href="https://www.facebook.com/groups/fmarketinvestors/"
              target="_blank"
            >
              <span>Tham gia cộng đồng nhà đầu tư Fmarket</span>
            </a>
          </li>
        </ul>
        <div class="btn-app">
          <a
            href="https://apps.apple.com/vn/app/fmarket-smart-invest/id1585628917"
            title="Fmarket Appstore "
            target="_blank"
          >
            <img
              src="https://fmarket.vn/trade/assets/images/homepage/footer-app.svg"
              alt="appstore-fmarket"
              loading="lazy"
              width="100"
              height="35"
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.fincorp.fmarket"
            title="Fmarket CH Play"
            target="_blank"
          >
            <img
              src="https://fmarket.vn/trade/assets/images/homepage/footer-google.svg"
              alt="chplay-fmarket"
              loading="lazy"
              width="100"
              height="35"
            />
          </a>
        </div>
      </div>
      <div class="col-12 isDesktop">
        <div class="footer-bottom">
          <h4>CÔNG TY CP FINCORP</h4>
          <p>
            Số giấy phép 01/GCN-UBCK cấp ngày 2/4/2018 , cấp bởi Ủy ban Chứng
            khoán Nhà nước
          </p>
          <p>
            Phòng 01 Tầng 7, Khu A Tòa nhà Bcons, 176/1 - 176/3 đường Nguyễn Văn
            Thương, phường 25, Quận Bình Thạnh, Thành phố Hồ Chí Minh, Việt Nam
          </p>
        </div>
      </div>
    </div>
  </div>
</footer>
