<ul class="nav nav-tabs" role="tablist" *ngIf="!isNoRoute">
    <li class="nav-item"  *ngFor="let tab of list; index as index"
    routerLinkActive="active" [routerLink]="tab.link">
      <a 
        class="nav-link"
        id="tab-{{id}}-{{index}}"
        routerLinkActive="active"
        [routerLink]="tab.link"
        ><span>{{tab.name}}</span>
      </a>
    </li>
</ul>

<ul class="nav nav-tabs" role="tablist" *ngIf="isNoRoute">
  <li class="nav-item"  [ngClass]="tab === item.code ? 'active' : ''" *ngFor="let item of list; index as index">
    <span (click)="changeTab(item.code)"
    class="nav-link">
      <span>{{item.name}}</span>
    </span>
  </li>

</ul>