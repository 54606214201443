import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, Input, OnChanges, Injector } from '@angular/core';
import { fadeInAnimation, fadeInOut } from '../../../common/service/routerTransition';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppQueries } from 'src/app/state';
import { EventManagerService } from 'src/app/common/service/event-manager.service';
import { Router } from '@angular/router';
import { ProductService } from 'src/app/product/product.service';
import { DetailProductFunction } from '../../../main/components/detail-product.abstract.function';
import { SessionStorageService } from 'ngx-webstorage';



@Component({
    selector: 'detail-new-fund',
    templateUrl: './detail-new-fund.html',
    styleUrls: ['./detail-fund.scss'],
    animations: [fadeInAnimation, fadeInOut],
    host: { '[@fadeInAnimation]': '' }
})

export class DetailNewFund extends DetailProductFunction implements OnInit, OnChanges, OnDestroy {
   

    constructor(
        public eventManager: EventManagerService,
        public appQueries: AppQueries,
        public router: Router,
        public sessionStorage: SessionStorageService,
        public activeModal: NgbActiveModal,
        public productService: ProductService,) {
        super(eventManager, appQueries, router, sessionStorage, activeModal, productService)
    }

}
