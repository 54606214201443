import {Constant} from "../constant";

/**
 * declare Jquery
 * */
declare let $: any;
export class ConvertDataService {
    static parseDate(date) {
        date = new Date(date);
        const day = date.getDate() > 9 ? date.getDate() : '0'+ date.getDate();
        const month = date.getMonth() > 8 ? (date.getMonth()+1) : '0'+ (date.getMonth() + 1);
        const year = date.getYear() + 1900;
        return day + '/' + month + '/' + year;
      }
      static parseMoney(number, noDecimal?) {
        if (!number) {
          return ''
        } else {
          number+= '';
          let str = '';
          for (let i=0; i < number.length; i++) {
            if ((number.charCodeAt(i) > 47 && number.charCodeAt(i) < 58) || number.charCodeAt(i) === 46) {
              str+= number[i];
            }  
          }
          number = str;
        }
        if ( number && number.length >= 0) {
          number+='';
          let array =number.split(',');
          number='';
          array.forEach((item) => {
            number+=item
          })
          array = number.split('.');
          let decimal = array[1];
          number= array[0]; let index = 0, str = '';
          if (!noDecimal) {
            if (decimal) {
              const position = decimal.length < 4 ? decimal.length : 3
              if (decimal[position -1] != 0) {
                decimal = this.decimalAdjust('ceil', parseFloat('0.' + decimal) , -3) + '';
                const max = decimal.length < 5 ? decimal.length : 5;
                decimal = decimal.substring(1, max)
              } else {
                if ( decimal.length < 4) {
                  decimal =  "." + decimal;  
                } else {
                  decimal =  "." + decimal.substring(0, 3)
                }
                
              }
            } else if (array.length === 2) {
              decimal = "."
            }
          } else {
              const decimalValue= '0.' + decimal;
            if (parseFloat(decimalValue) > 0.5) {
              number = parseInt(number) + 1;
            } else {
              number = parseInt(number);
            }
            number += '';
            decimal = '';
            decimal = null;
          }
          for (let i= number.length - 1; i >= 0; i--) {
            index++;
            str= number.charAt(i) + str;
            if (index % 3 === 0) {
              str = ',' + str;
              index = 0;
            }
          }
          if (str.charAt(0) === ',') {
            str = str.substring(1, str.length);
          }
          return decimal ? str + decimal : str ? str : '';
        } else {
          return '';
        }
      }
      static revertMoneytoNumber(number, returnNull?) {
        number && (number+='');
        if (number && number.length > 0) {
          let array =number.split(',');
          number='';
          array.forEach((item) => {
            number+=item
          })
          return parseFloat(number)
        } else if (number !== 0) {
          return !returnNull ? 0 : null;
        } else {
          return 0
        }
      }
      static decimalAdjust(type, value, exp) {
        if (exp < 0) {
          exp = exp * -1;
        }
        let valueMul = 1;
        for (let i=1; i <= exp; i++) {
          valueMul= valueMul * 10;
        }
        return Math[type](value * valueMul) / valueMul;
      }
      static parsePhone(value) {
        if (value.substring(0,1) == '0') {
          value = value.substring(1, value.length);
        }
        let text = value.substring(0, 3);;
        if (value.length > 3) {
          text+= ' ' + value.substring(3, 6);
        }
        if (value.length > 6) {
          text+= ' ' + value.substring(6, 12);
        }
        return text;
      }
      static getTypes(type) {
        let types;
        switch (type) {
          case Constant.CODE_ORDER_FUND: 
            types = [ 
              Constant.CODE_NEW_FUND,
              Constant.CODE_TRADING_FUND,
            ]
          break;
          case Constant.CODE_GOLD: 
            types = [ 
              Constant.CODE_GOLD,
            ]
          break;
          case Constant.CODE_ORDER_BOND: 
            types = [ 
              Constant.CODE_FIXED_BOND,
              Constant.CODE_FLEXIBLE_BOND,
              Constant.CODE_MATURITY_BOND
            ]
          break;
          case Constant.CODE_ORDER_CD: 
            types = [ 
              Constant.CODE_FIXED_INTEREST_ON_FIXED_CD,
              Constant.CODE_FLEXIBLE_CD,
              Constant.CODE_FLEXIBLE_INTEREST_ON_FIXED_CD,
            ]
          break;
          case Constant.CODE_FUND: 
            types = [ 
              Constant.CODE_NEW_FUND,
              Constant.CODE_TRADING_FUND,
            ]
          break;
          case Constant.CODE_BOND: 
            types = [ 
              Constant.CODE_FIXED_BOND,
              Constant.CODE_FLEXIBLE_BOND,
              Constant.CODE_MATURITY_BOND
            ]
          break;
          case Constant.CODE_CD: 
            types = [ 
              Constant.CODE_FIXED_INTEREST_ON_FIXED_CD,
              Constant.CODE_FLEXIBLE_CD,
              Constant.CODE_FLEXIBLE_INTEREST_ON_FIXED_CD,
            ]
          break;
          default: 
            types = [ 
              Constant.CODE_NEW_FUND,
              Constant.CODE_TRADING_FUND,
              Constant.CODE_FIXED_BOND,
              Constant.CODE_FLEXIBLE_BOND,
              Constant.CODE_MATURITY_BOND,
              Constant.CODE_FIXED_INTEREST_ON_FIXED_CD,
              Constant.CODE_FLEXIBLE_CD,
              Constant.CODE_FLEXIBLE_INTEREST_ON_FIXED_CD,
            ]
          break;
        }
        return types;
      }
}