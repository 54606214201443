import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, EventEmitter, SimpleChanges, Output, Input } from '@angular/core';
import { Constant } from '../../../../../../common/constant';
import { fadeInAnimation, fadeInOut } from '../../../../../../common/service/routerTransition';
import { EventManagerService } from 'src/app/common/service/event-manager.service';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { InvestorService } from 'src/app/main/investor/services/investor.service';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { Subscription } from 'rxjs';
import { AppQueries } from 'src/app/state';
/**
 * declare Jquery
 * */
declare let $: any;

@Component({
  selector: 'info-payment',
  templateUrl: './info-payment.html',
  styleUrls: ['./info-payment.scss'],
  animations: [fadeInAnimation, fadeInOut],
  host: { '[@fadeInAnimation]': '' }
})

export class InforPayment implements OnInit, OnDestroy {
  @Input('transaction') transaction;
  @Input('paymentInfo') paymentInfo;
  @Input('isContributeGoal') isContributeGoal: boolean = false;
  info: any;
  public Constant = Constant;
  isCopied1: boolean = false;
  isCopied2: boolean = false;
  isCopied3: boolean = false;
  isCopied4: boolean = false;
  isCopied5: boolean = false;
  inputDisabled: boolean = false;
  isDevice: any = false;
  isTransferNormal: any = false;
  selectedBank: any;
  banks: any = [];
  byWoori: boolean = false;
  byTnex: boolean = false;
  isNormal: boolean = true;
  thirdAppId: any;
  subscriptions: Subscription[] = [];
  constructor(
    private eventManager: EventManagerService,
    private appQueries: AppQueries,
    public activeModal: NgbActiveModal,
    public investorService: InvestorService,
    public modalService: NgbModal,
    private $sessionStorage: SessionStorageService,
  ) {
  }
  ngOnInit() {
    this.selectedBank = {};
    this.info = this.paymentInfo || {};
    this.transaction = this.transaction || {};
    this.inputDisabled = true;
    this.info.shortNameBank = this.info.shortNameBank || '';
    if (this.info.shortNameBank.includes('HSBC') || this.info.shortNameBank.includes('Standard Chartered')) {
      this.isTransferNormal = true;
    } else {
      this.isTransferNormal = false;
    }
    const orderQR = sessionStorage.getItem('orderQR');
    this.info.qrPaymentUrl = orderQR ? 'data:image/png;base64,' + orderQR : this.info.qrPaymentUrl;
    sessionStorage.setItem('orderQR', '');
    this.subscriptions = [
      this.appQueries.dataSource$
      .subscribe((dataSource) => {
        const resources = dataSource || { thirdApps: {data: []}};
        const thirdAppId = this.thirdAppId = this.$sessionStorage.retrieve('thirdAppId');
        this.byWoori = !!(resources.thirdApps.data || []).find(t => t.id === +thirdAppId && t.code === 'woori');
        this.byTnex = !!(resources.thirdApps.data || []).find(t => t.id === +thirdAppId && t.code === 'tnex');
        this.isNormal = !this.byWoori && !this.byTnex;
      }),
      this.eventManager.subscribe( 'close-popup', (res) => {
        this.dismiss();
      }),
      this.appQueries.isDevice$.subscribe((isDevice) => {
        this.isDevice = isDevice;
      })
    ];
    $(document).ready(function () {
      $('input[name="intervaltype"]').click(function () {
        $(this).tab('show');
        $(this).removeClass('active');
      });
    });
  }
  ngOnDestroy(): void {
    this.eventManager.destroys(this.subscriptions);
  }
  openLink() {
    window.open(this.selectedBank.link, '_blank');
  }
  confirm() {
    if (this.isContributeGoal) {
      this.eventManager.broadcast({
        name: 'contribute-goal-confirm-payment',
        content: {
          transaction: this.transaction,
        }
      });
      return;
    }
    if (this.byTnex) {
      this.eventManager.broadcast({
        name: 'callback-confirm-payment',
        content: {
          byWoori: this.byWoori,
          byTnex: this.byTnex,
          transaction: this.transaction,
        }
      })
    } else {
      this.eventManager.broadcast({
        name: 'open-confirm-payment',
        content: {
          transaction: this.transaction,
          byWoori: this.byWoori,
          byTnex: this.byTnex
        }
      })
    }
    
  }
  dismiss() {
    this.activeModal.dismiss();
  }
  refresh() {
    this.investorService.getDetailOrder(this.info.code)
      .subscribe((res: any) => {
        this.info.qrPaymentUrl = res.data.qrPaymentUrl;
      })
  }
  download() {
    window.open(this.info.qrPaymentUrl, '_blank');
  }
  copy(isCopied) {
    this.isCopied1 = false;
    this.isCopied2 = false;
    this.isCopied3 = false;
    this.isCopied4 = false;
    this.isCopied5 = false;
    this[isCopied] = true;
  }
  
  openGuidePaymenmtQRPopup() {
    this.eventManager.broadcast({
      name: "open-guide-payment-QR-popup",
      content: {}
    });
  }

}