import { Injectable, NgZone } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpErrorResponse, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { EventManagerService } from '../service/event-manager.service';
import { DialogService } from '../service/dialog.service';
import { AccountService } from 'src/app/main/services/account.service';
import { Router } from '@angular/router';
declare let $;
@Injectable({ providedIn: 'root' })
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(
    private dialogService: DialogService,
    private router: Router,
    private ngZone: NgZone,
    private eventManager: EventManagerService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.eventManager.broadcast({ name: 'httpError-401', content: err });
            } else if (err.status === 503) {
              this.ngZone.run(() => {
                this.router.navigate(['503']);
              }, 500)
            } else if (err.status) {
              this.eventManager.broadcast({ name: 'Fmarket.httpError', content: err });
            } else {
              this.eventManager.broadcast({ name: 'Fmarket.maintainHttpError', content: err });
            }
          }
          return err;
        }
      )
    );
  }
}
