<!-- Popup thông tin chi tiết CCQ đang giao dịch -->
<div class="modal-contents new-fund">

  <!-- Modal Header -->
  <div class="modal-header">
    <div class="row">
      <div class="col-11 col-lg-11">
        <div class="d-flex align-items-center">
          <img [src]="product.owner?.avatarUrl !== null ? product.owner?.avatarUrl : ' ' "
            [ngClass]="product.owner?.avatarUrl === null ? 'hidden' : ''" alt="" class="product-logo">
          <div class="d-flex flex-column">
            <div class="d-flex align-items-center">
              <span *ngIf="!isDevice">{{product.name}} ({{product.shortName}})</span>
              <span *ngIf="isDevice">{{product.shortName}}</span>
              <label class="ml-2" id="label-ipo">{{'Quỹ IPO' | translate}}</label>
            </div>
            <label class="issuer-name">{{product.owner?.shortName}}</label>
          </div>
        </div>


      </div>
      <button type="button" class="btn close-btn" (click)="dismiss()"  *ngIf="!isDetailFullPage">
        <img src="./assets/images/icon/ic-black-close.svg" alt="">
      </button>
    </div>
  </div>

  <!-- Modal body -->
  <div class="modal-body horizon-mobile">
    <div class="row modal-row">

      <div class="col-lg-12 col-sm-12 col-md-12 col-12">
        <div class="card">
          <span *ngIf="product?.isProductIpo">{{'Giá phát hành' | translate}}</span>
          <span *ngIf="!product?.isProductIpo">{{'Giá trị mua' | translate}}</span>
          <div class="group-text">
            <label class="price" for="">{{product.nav | number}} VND</label>
          </div>
          <label class=" pb-3">{{product.description}}</label>
        </div>

      </div>

      <div class="col-lg-12" *ngIf="!isDevice">
        <div class="table-responsive">
          <table class="table">
            <tbody class="table__item">
              <tr>
                <td><label for="">{{'Tổ chức phát hành' | translate}}</label></td>
                <td><span>{{product.owner?.shortName}}</span></td>
              </tr>

              <tr  *ngIf="!isPendingIPO">
                <td><label for="">{{'Đặt lệnh và chuyển tiền mua' | translate}}</label></td>
                <td><span>{{'Từ ngày' | translate}} <strong>{{product.productFund?.ipoStartTime | date_vn:
                      'dd-MM-yyyy'}}</strong> {{'Đến' | translate}}
                    <strong>{{product.productFund?.ipoEndTime | date_vn: 'dd-MM-yyyy'}}</strong></span></td>
              </tr>
              <tr  *ngIf="isPendingIPO">
                <td><label for="">{{'Kết thúc chào bán IPO' | translate}}</label></td>
                <td><span><strong>{{product.productFund?.ipoEndTime | date_vn: 'dd-MM-yyyy'}}</strong></span></td>
              </tr>
              <tr *ngIf="product.customField">
                <td><label for="">{{product.customField | translate}}</label></td>
                <td><span>{{product.customValue}}</span></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="col-lg-12 col-sm-12 col-md-12 col-12" *ngIf="isDevice">
        <div class="block block-up">
          <div class="row">
            <div class="col-lg-7 col-sm-3 col-md-3 col-12">
              <div class="block-item">
                <label for="">{{'Tổ chức phát hành' | translate}}</label>
                <span>{{product.owner?.shortName}} </span>
              </div>
            </div>
            <div class="col-lg-7 col-sm-5 col-md-5 col-12" *ngIf="!isPendingIPO">
              <div class="block-item">
                <label for="">{{'Đặt lệnh và chuyển tiền mua' | translate}}</label>
                <span><span>{{'Từ ngày' | translate}} <strong>{{product.productFund?.ipoStartTime | date_vn:
                      'dd-MM-yyyy'}}</strong> {{'Đến' | translate}}
                    <strong>{{product.productFund?.ipoEndTime | date_vn: 'dd-MM-yyyy'}}</strong></span></span>
              </div>
            </div>
            <div class="col-lg-7 col-sm-5 col-md-5 col-12" *ngIf="isPendingIPO">
              <div class="block-item">
                <label for="">{{'Kết thúc chào bán IPO' | translate}}</label>
                <span><strong>{{product.productFund?.ipoEndTime | date_vn: 'dd-MM-yyyy'}}</strong></span>
              </div>
            </div>
            <div class="col-lg-4 col-sm-4 col-md-4 col-12" *ngIf="product.customField">
              <div class="block-item">
                <label for="">{{product.customField | translate}}</label>
                <span>{{product.customValue}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12">
        <div class="d-flex flex-column">
          <button class="btn btn--blue" type="button" (click)="buy()" prevent-multi-clicks  *ngIf="!isDetailFullPage &&  !isPendingIPO">
            {{'Đầu tư' | translate}}
          </button>
          <span *ngIf="isPendingIPO">Hẹn gặp lại quý khách vào ngày giao dịch đầu tiên của Quỹ</span>
        </div>
      </div>
    </div>
  </div>
</div>