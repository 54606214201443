import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import { EventManagerService } from '../common/service/event-manager.service';
import { Subscription } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
declare let $: any;

@Component({
  selector: "loading-modal",
  templateUrl: "./loading-modal.html",
  styleUrls: ["loading-modal.scss"]
})

export class LoadingModal implements OnInit, OnDestroy{
  subscribe: Subscription;
  constructor(
    private activeModal: NgbActiveModal,
    private eventManagerService: EventManagerService
  ) { }

  ngOnInit() {
  }
  ngOnDestroy() {
  }
}
