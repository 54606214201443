<div class="modal-contents content-confirm-popup">

  <!-- Modal Header -->
  <div class="modal-header" [ngClass]="!header ? 'no-header' : ''">
    <button type="button" class="close" (click)="dismiss()">
      <img title="Close" src="./assets/images/icon/ic-gray-close.svg">
    </button>
  </div>

  <!-- Modal body -->
  <div class="modal-body">
    <div class="row justify-content-center confirm-title">
      <div class="col-12" *ngIf="iconImage">
        <img [src]="iconImage" width="60" height="60">
      </div>
      <div class="col-12 mt-3 text-center" *ngIf="title">
        <span class="detail-title">{{title}}</span>
      </div>
      <div class="col-12 mt-3" *ngIf="titleHTML">
        <label [innerHTML]="titleHTML"></label>
      </div>
    </div>
  </div>

  <div class="modal-footer d-flex justify-content-between" [ngClass]="!buttonCancelLabel ? 'fix-btn' : ''">
    <button class="btn btn--grey" [ngClass]="!buttonLabel ? 'col-12' : 'col-6'" type="button" (click)="cancel()"
      *ngIf="buttonCancelLabel">{{buttonCancelLabel}}</button>
    <button class="btn btn--green" [ngClass]="!buttonCancelLabel ? 'fixes-btn col-12' : 'col-6'" (click)="argree()" prevent-multi-clicks
      *ngIf="buttonLabel">{{buttonLabel}}</button>
  </div>

</div>