// Imports
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Constant } from "../../common/constant";
import { DomSanitizer } from '@angular/platform-browser';
import { AppQueries } from 'src/app/state';
import { SessionStorageService } from 'ngx-webstorage';

// Component Decorator
@Component({
  selector: 'chatting',
  templateUrl: './chatting.html',
  styleUrls: ['./chatting.scss']
})

// Component Class
export class ChattingComponent implements OnInit {
  public Constant = Constant;
  isLoadingPartner : boolean = false;
  thirdAppId: any;

  constructor(
      public sanitizer:DomSanitizer,
      public appQueries: AppQueries,
    private $sessionStorage: SessionStorageService,

  ) {

  }
  ngOnInit() {
    setTimeout(() => {
      this.isLoadingPartner = true;
    }, 2000)
    this.initData();
  }
  initData() {
    this.appQueries.dataSource$
      .subscribe((dataSource) => {
        const resources = dataSource || {};
        this.thirdAppId = this.$sessionStorage.retrieve('thirdAppId');
    });
  }
  sanitize(url:string){
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
  getName(name) {
    if (Constant.checkAndroid()) {
      return  name.substring(1,name.length)
    } else return name
  }
}