import { CopyProperties } from '../decorator/copy-properties.decorator';
import { Product } from './product.model';

@CopyProperties()
export class Gold  {
  id: number;
  name: string;
  shortName: string;
  code: string;
  description: string;
  status: string;
  type: string;
  productGold: any;
  owner: any;
  constructor(params?: any) {
    this.id = 0;
    this.name = '';
    this.shortName = '';
    this.code = '';
    this.description = '';
    this.status = '';
    this.type = '';
    this.productGold = null;
    this.owner = null;
  }
}
