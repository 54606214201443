import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import { Constant } from "../../constant";

@Component({
  selector: 'no-data',
  templateUrl: './no-data.component.html',
  styleUrls: ['./no-data.component.scss']
})

export class NoDataComponent {
  public Constant = Constant;
  @Input() title;
  constructor(
  ) {

  }
  ngOnInit() {
  }
}