import { Component } from '@angular/core';
import { EventManagerService } from '../../service/event-manager.service';
declare let $;
@Component({
  selector: 'uploading-icon',
  templateUrl: './uploading-icon.component.html',
  styleUrls: ['./uploading-icon.component.scss']
})
export class UploadingIcon{
  constructor(
    private eventManager: EventManagerService
  ) {

  }
}
