import { Injectable } from '@angular/core';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { Observable, Subject, Subscription } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthorizeService {
  private userIdentity: any;
  private authorities: any;
  private authenticated = false;
  public authenticationState = new Subject<any>();
  public informationSubscribe$: Subject<any> = new Subject();
  constructor(
    private localStorage$: LocalStorageService
    ) {
  }
  setAuthorities(user) {
    this.authorities = {};
    if (user.userType === 'FUND_AGENT') {
      this.authorities.AGENT = {};
      if (user.isHsbcAgent) {
        this.authorities.AGENT.HSBC = true;
      } else {
        this.authorities.AGENT.VSD = true;
      }
    }
    this.authenticationState.next(this.userIdentity);
  }
  getAuthenticationState(): Observable<any> {
    return this.authenticationState.asObservable();
  }
  hasAuthority(authorities: string[]): boolean {
    let result = Object.assign({}, this.authorities);
    authorities.forEach((authority) => {
      if (result) {
        result = result[authority];
      }
    })
    return result;
  }
  hasAnyAuthority(authorities: string[]): boolean {
    if (!this.authenticated || !this.userIdentity || !this.userIdentity.authorities) {
      return false;
    }

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < authorities.length; i++) {
      if (this.userIdentity.authorities.includes(authorities[i])) {
        return true;
      }
    }

    return false;
  }
}
