import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { SessionStorageService } from 'ngx-webstorage';
import { Constant } from 'src/app/common/constant';
declare let $: any;
@Injectable({ providedIn: 'root' })
export class  MaintainceService  {
    APIS: any[] = [];
    roboAPIS = ['robof/res/all', 'robof/robo-advisor/process','robof/robo-advisor/load','web/investors/robo/token/create'];
    constructor(
        private sessionStorage: SessionStorageService,
        private router: Router) {
    }
    addAPI(message) {
        if (!message) {
          return;
        }
        var list = message.split('://');
        var list1 = list[1].split('/');
        list1.splice(0, 1);
        var text = list1.join('/')
        const api = text.split(':')[0];
        this.APIS.push(api);
        const currentPage = this.sessionStorage.retrieve('currentPage');
        let result = false;
        switch (currentPage) {
            // case 'market':
            // break;
            // case 'asset':
            // break;
            // case 'register-trade':
            // break;
            // case 'profile':
            // break;
            case Constant.ROBO_PAGE:
                result = this.APIS.some((api) => {
                    return this.roboAPIS.some(API => API === api);
                });
                if (result) {
                    this.APIS = [];
                    setTimeout(() => {
                        this.router.navigate(['/account/investor/503'], {queryParams: {type: 'robo'} });
                    }, 100)
                }
            break;
            default:
                result = this.APIS.some((api) => {
                    return this.roboAPIS.every(API => API !== api);
                });
                if (result) {
                    this.APIS = [];
                    setTimeout(() => {
                        this.router.navigate(['/account/investor/503'], {queryParams: {type: 'normal'} });
                    }, 100)
                }
            break;
        }
    }

}
