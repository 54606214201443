import { Action } from './base/action.model';

export enum AppActionNames {
  STORE_STATE = '[APP] Store State',
  STORE_TOKEN = '[APP] Store Token',
  STORE_INFO_USER = '[APP] Store Info User', 
  STORE_THEME = '[APP] Store Theme', 
  STORE_SOURCE_INFO = '[APP] Store SourceInfo', 
  GET_INFO_USER = '[APP] Get Info User',
  GET_DATA_SOURCE  = '[APP] Get Data Source',
  OPEN_OTP = '[APP] Open OTP',
  SET_TIME_OTP = '[APP] Set Time OTP',
  SET_DATA_OTP = '[APP] Set Data OTP',
  SET_IS_DEVICE = '[APP] Set Is Device'
}
export class StoreState implements Action {
  static readonly type = AppActionNames.STORE_STATE;
  constructor(public payload: any) {}
}
export class StoreToken implements Action {
  static readonly type = AppActionNames.STORE_TOKEN;
  constructor(public payload: any) {}
}
export class StoreTheme implements Action {
  static readonly type = AppActionNames.STORE_THEME;
  constructor(public payload: any) {}
}
export class StoreDataSource implements Action {
  static readonly type = AppActionNames.STORE_SOURCE_INFO;
  constructor(public payload: any) {}
}
export class StoreInfoUser implements Action {
  static readonly type = AppActionNames.STORE_INFO_USER;
  constructor(public payload: any) {}
}
export class GetInfoUser implements Action {
  static readonly type = AppActionNames.GET_INFO_USER;
  constructor(public payload?: any) {}
}
export class GetDataSource implements Action {
  static readonly type = AppActionNames.GET_DATA_SOURCE;
  constructor(public payload?: any) {}
}

export class OpenOTP implements Action {
  static readonly type = AppActionNames.OPEN_OTP;
  constructor(public payload?: any) {}
}
export class SetTimeOTP implements Action {
  static readonly type = AppActionNames.SET_TIME_OTP;
  constructor(public payload?: any) {}
}
export class SetDataOTP implements Action {
  static readonly type = AppActionNames.SET_DATA_OTP;
  constructor(public payload?: any) {}
}
export class SetIsDevice implements Action {
  static readonly type = AppActionNames.SET_IS_DEVICE;
  constructor(public payload?: any) {}
}
