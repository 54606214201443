import {AfterViewInit, Component, OnDestroy, OnInit, Input, Output, EventEmitter} from "@angular/core";
import { EventManagerService } from '../../service/event-manager.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Constant } from '../../constant';
/**
 * declare Jquery
 * */
declare let $: any;

@Component({
  selector: 'error-popup',
  templateUrl: "./error-popup.html",
  styleUrls: ["./error-popup.scss"]
})

export class ErrorPopupComponent implements OnInit {
    @Output() result: EventEmitter<any> = new EventEmitter();
    @Output() message: any;
    @Input() title: any;
    @Input() isOkButton: any;
    @Input() iconImage: any = Constant.icon_url_fail;
    @Input() isDismissAll: boolean;
    public Constant = Constant;
    @Input() properties;
    constructor(
        public eventManger: EventManagerService,
        public activeModal: NgbActiveModal,
        public ngModal: NgbModal
    ) {}
    ngOnInit() {
        // console.log('open toast: ', DateUtil.parseDate(Date.now(), 'hh:mm:ss'));
        if (this.properties) {
            Object.keys(this.properties).forEach((key) => {
                this[key] = this.properties[key];
            });
        }
    }
    dismiss() {
        if (!this.isDismissAll) {
            this.activeModal.dismiss();
        } else {
            this.ngModal.dismissAll();
        }
        this.result.emit(true);
    }
}
