import {trigger, state, style, transition, animate} from '@angular/animations';

export function routerTransition() {
  return trigger('routerTransition', [
    state('void', style({
      position: 'fixed',
      width: '100%', height: '100%',
      'overflow-x': 'hidden',
      '-webkit-overflow-scrolling': 'touch'
    })),
    state('*', style({
      position: 'fixed',
      width: '100%',
      height: '100%',
      'overflow-x': 'hidden',
      '-webkit-overflow-scrolling': 'auto'
    })),
    transition(':enter', [  // before 2.1: transition('void => *', [
      style({transform: 'translateX(100%)'}),
    ]),
    transition(':leave', [  // before 2.1: transition('* => void', [
      style({transform: 'translateX(0%)'}),
    ])
  ]);
}

export const fadeInAnimation =
  trigger('fadeInAnimation', [
    // route 'enter' transition
    transition(':enter', [

      // styles at start of transition
      style({ opacity: 0 }),

      // animation and styles at end of transition
    ]),
  ]);

export const fadeInOut =
  trigger('fadeInOut', [
    transition(':enter', [   // :enter is alias to 'void => *'
      style({opacity:0}),
    ])
  ]);

export const fadeIn =
  trigger('fadeIn', [
    transition(':enter', [
      style({ opacity: '0' }),
    ]),
  ]);

