
import {Constant} from "../common/constant";
export const ROUTE_INVESTOR: any = [
  {
    path: Constant.url_investor_market,
    children: [
      {path: 'buy-fund',},
    ]
  },
  {path: Constant.url_investor_main_profile},
  {path: Constant.url_investor_main_profile + '/:tab'},
  {path: Constant.url_investor_register_trade},
  {path: Constant.route_change_password},
  {path: Constant.route_change_email},
  {path: Constant.route_change_phone},
  {path: Constant.route_delete_account},
  {path: Constant.url_investor_market+'/:tab'},
  {path: Constant.url_investor_asset},
  {path: Constant.url_investor_report},
  {path: Constant.url_investor_help},
];