import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, Input, Injector } from '@angular/core';
import { fadeInAnimation, fadeInOut } from '../../../common/service/routerTransition';
import { Constant } from '../../../common/constant';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EventManagerService } from 'src/app/common/service/event-manager.service';
import { AppQueries } from 'src/app/state';
import { Router } from '@angular/router';
import { ProductService } from 'src/app/product/product.service';
import { DetailProductFunction } from '../../../main/components/detail-product.abstract.function';
import { SessionStorageService } from 'ngx-webstorage';

/**
    * declare Jquery
    * */
declare let $: any;


@Component({
    selector: 'detail-trading-fund',
    templateUrl: './detail-trading-fund.html',
    styleUrls: ['../detail-new-fund/detail-fund.scss'],
    animations: [fadeInAnimation, fadeInOut],
    host: { '[@fadeInAnimation]': '' }
})

export class DetailTradingFund extends DetailProductFunction implements OnInit, OnDestroy {
    Constant = Constant;

    isDevice: any = false;

    constructor(
        public eventManager: EventManagerService,
        public appQueries: AppQueries,
        public router: Router,
        public sessionStorage: SessionStorageService,
        public activeModal: NgbActiveModal,
        public productService: ProductService) {
        super(eventManager, appQueries, router, sessionStorage, activeModal, productService)
    }
}
