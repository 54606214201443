import { AfterViewInit, Component, OnDestroy, OnInit, Input, } from '@angular/core';
import { fadeInAnimation, fadeInOut } from '../../../common/service/routerTransition';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppQueries } from 'src/app/state';
import { EventManagerService } from 'src/app/common/service/event-manager.service';
import { Router } from '@angular/router';
import { SessionStorageService } from 'ngx-webstorage';

@Component({
    selector: 'asset-distribution',
    templateUrl: './asset-distribution.html',
    styleUrls: ['./asset-distribution.scss'],
    animations: [fadeInAnimation, fadeInOut],
    host: { '[@fadeInAnimation]': '' }
})

export class AssetDistribution implements OnInit {
    @Input('assetDistribution') assetDistribution;
    @Input('updateTime') updateTime;
    isDevice: any = false;
    dataChart: any = {};
    constructor(
        public eventManager: EventManagerService,
        public appQueries: AppQueries,
        public router: Router,
        public sessionStorage: SessionStorageService,
        public activeModal: NgbActiveModal) {
    }

    ngOnInit() {
        this.dataChart = {
            dateLabels: this.assetDistribution.map((item) => item.assetType.name),
            labels: this.assetDistribution.map((item) => item.assetType.name),
            datasets: [
              {
                label: 'Sở hữu',
                data: this.assetDistribution.map((item) => item.assetPercent),
                backgroundColor: this.assetDistribution.map((item) => item.assetType.colorCode)
              }
            ]
        };
    }
}
