<!-- Modal content-->
<div class="modal-contents">
  <div class="modal-body">
    <div class="text-center">
      <div class="col-md-12 text-center">
        <img [src]="iconImage" class="image-success">
        <h4 class="popup-title">{{'Internet bị mất kết nối hoặc gián đoạn' | translate}}</h4>
        <h4 class="popup-message">{{'Vui lòng kiểm tra lại kết nối mạng và tải lại trang.' | translate}}</h4>
      </div>
    </div>
  </div>
  <div class="modal-footer justify-content-center">
    <button class="btn btn-ok" (click)="dismiss()" prevent-multi-clicks>{{'Tải lại trang' | translate}}</button>
  </div>
</div>