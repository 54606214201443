// Imports
import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Constant } from "../../constant";
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Dispatcher, GetInfoUser } from '../../../state';
declare let $: any;
// Component Decorator
@Component({
  selector: 'maintenance',
  templateUrl: './maintenance.html',
  styleUrls: ['./maintenance.scss']
})

// Component Class
export class MaintenanceComponent implements OnInit, OnDestroy {
  public Constant = Constant;
  resource: any = {};
  myInterval: any;
  constructor(
    private dispatcher: Dispatcher,
    public router: Router,
    public sanitizer:DomSanitizer,
  ) {

  }
  ngOnInit() {
    this.myInterval  = setInterval(() => {
      this.checkAPI();
    }, 30 * 1000)
  }
  checkAPI() {
    this.dispatcher.fire(new GetInfoUser(true));
  }
  ngOnDestroy(): void {
    clearInterval(this.myInterval);
  }
}