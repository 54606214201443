import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, Input, OnChanges, Injector } from '@angular/core';
import { fadeInAnimation, fadeInOut } from '../../../common/service/routerTransition';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppQueries } from 'src/app/state';
import { EventManagerService } from 'src/app/common/service/event-manager.service';
import { Router } from '@angular/router';
import { SessionStorageService } from 'ngx-webstorage';

/**
 * declare Jquery
 * */
declare let $: any;

@Component({
    selector: 'top-holding-fund',
    templateUrl: './top-holding-fund.html',
    animations: [fadeInAnimation, fadeInOut],
    host: { '[@fadeInAnimation]': '' }
})

export class TopHoldingFund implements OnInit {
    @Input('topHolding') topHolding;
    @Input('updateTime') updateTime;
    @Input('bondList') bondList;
    isDevice: any = false;

    constructor(
        public eventManager: EventManagerService,
        public appQueries: AppQueries,
        public router: Router,
        public sessionStorage: SessionStorageService,
        public activeModal: NgbActiveModal) {
    }

    ngOnInit() {
        this.appQueries.isDevice$.subscribe((isDevice) => {
            this.isDevice = isDevice;
        });
    }
}
