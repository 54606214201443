import {Injectable, OnInit} from '@angular/core';
import {Constant} from "../common/constant";
import { ConvertDataService } from '../common/service/convertData.service';
import { DataService } from '../common/service/data.service';
import { Product } from '../common/models/product.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Bond } from '../common/models/bond.model';
import { Gold } from '../common/models/gold.model';

@Injectable({ providedIn: 'root' })
export class ProductService {
  resource: any = {};

  constructor(
    private http: HttpClient,
    private dataService: DataService,
  ) {
    this.resource = this.dataService.getFull();
    dataService.subData()
    .then((res) => {  
      this.resource = res;
    })
  }
  
  public getProducts(body) {
    return  this.http.post(environment.urlBackEnd + Constant.api_common_products_filter, body);
  }
  getDataChart(body) {
    return this.http.post(environment.urlBackEnd  + Constant.api_get_data_chart, body);
  }
  
  parse(product: any) {
    let object: any;
    if (product.type === 'BOND') {
      object = Bond.parseBond(product, this.resource);
    } else if (product.type === 'GOLD') {
      object = new Gold(product);
    } else {
      object = this.convertProduct(new Product(product), this.resource);
      
    }
    return object;
  }  
  convertProduct(product: any, dataSource?) { 
    if (!product) {
      return new Product();
    }
    product.owner = product.owner || {};
    if (product.productBond && product.productBond.issuerInfo && product.productBond.issuerInfo.id) {
        product.owner = product.productBond.issuerInfo;
    }
    product.productAssetAllocationModelList = product.productAssetAllocationModelList || [];
    product.assets = product.productAssetAllocationModelList.map(ass => ass.name).join(", ");
    product.productFeeDiscountList = product.productFeeDiscountList || [];
    product.productFeeList = product.productFeeList || [];
    product.productFeeList.sort((a, b) => {
      if (a.beginVolume > b.beginVolume) {
          return 1;
      }
      if (a.beginVolume < b.beginVolume) {
          return -1;
      }
          return 0; 
    })
    product.feeBuys = [];
    product.normalTimeHolds = [];
    product.productFeeList.forEach((fee) => {
      if (fee.type === 'BUY') {
        product.feeBuys.push(fee);
      } else if (fee.type === 'SELL')  {
        product.normalTimeHolds.push(fee);
      }
    })
    product.website = product.website || '';
    if (product.website.substring(0, 4) !== 'https') {
      product.website = 'https://' + product.website;
    }
    
    if (!dataSource) {
      return product;
    }
    product.productTransactionDateList = product.productTransactionDateList || [];
    if (Constant.arraysEqual(Constant.DAYS_OF_WEEK_WORKING_DAY, product.productTransactionDateList)) {
      product.transactionDate = 'Thứ Hai đến Thứ Sáu';
    } else if (product.productTransactionDateList.length > 0) {
      product.transactionDate = dataSource.daysOfWeek
        .filter(day => product.productTransactionDateList.indexOf(day.id) > -1)
        .map(day => day.name)
        .join(', ');
    } else {
      product.transactionDate = '';
    }
    product.bank = product.bank || product.productSupervisoryBankAccount || {};
    (dataSource.banks || []).forEach((bank) => {
      if (bank.id === product.bank.bankId) { 
          product.bank.bankName = bank.shortName; 
      }
    });
    return product;
  }
}
