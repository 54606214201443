import { Constant } from "../constant";
import { CopyProperties } from "../decorator/copy-properties.decorator";
import { ConvertDataService } from "../service/convertData.service";
import { Product } from "./product.model";
@CopyProperties()
export class Bond extends Product {
  status: number;
  message: String;
  time: number;
  data: any;
  extra: any;
  productBond: any;
  bondTerm: any;
  isSubprime: boolean;
  isFixedYield: boolean;
  isProofOfRight: boolean;
  isTransferred: boolean;
  productSupervisoryBankAccount: any;
  maturityAt: any;
  maturityDate: any;
  constructor(params?: any) {
    super(params);
    this.status = 0;
    this.message = "";
    this.time = 0;
    this.data = null;
    this.extra = null;
    this.maturityAt = null;
    this.maturityDate = null;
    this.productSupervisoryBankAccount = null;
    this.isSubprime = false;
    this.isFixedYield = false;
    this.isProofOfRight = false;
    this.isTransferred = false;
    this.bondTerm = null;
  }

  setFloatRate(product) {
    if (
      product &&
      product.productBond &&
      product.productBond.interestPaymentTimelineModels &&
      !product.productBond.floatRate
    ) {
      let floatRate = [],
        fixRate = [];
      let positionFixed = -1,
        positionFloat = -1;
      product.productBond.interestPaymentTimelineModels.forEach(
        (timeline, index) => {
          if (!timeline.isFloatingPeriod) {
            positionFixed = index;
          } else positionFloat = index;
        }
      );
      if (positionFixed > -1) {
        product.productBond.fixRateDate = true;
      }
      if (positionFloat > -1) {
        product.productBond.floatRateDate = true;
      }
      if (positionFixed > -1 && positionFloat > -1) {
        let timeline =
          product.productBond.interestPaymentTimelineModels[positionFixed];
        product.productBond.stringDate =
          "Bắt đầu từ " +
          ConvertDataService.parseDate(timeline.notReceivedInterestSince);
      }
      if (floatRate.length > 0) {
        product.isFloat = true;
      }
    }
    return product;
  }
  static parseBond(product: any, resource) {
    if (product.productBond) {
      product.bondTerm = this.calculateBondTerm(
        product.maturityAt,
        product.endIssueAt
      );
      if (product.productBond && product.productBond.yieldBankId) {
        resource.banks.forEach((bank) => {
          if (product.productBond.yieldBankId === bank.id) {
            product.productBond.bank = bank.shortName;
            product.productBond.yieldBankBranchTmp =
              product.productBond.yieldBankBranch.name;
          }
        });
      }
      resource.paymentPeriodTypes.forEach((paymentPeriodType) => {
        if (paymentPeriodType.id === product.productBond.paymentPeriodId) {
          product.productBond.paymentPeriod = paymentPeriodType.name;
          product.productBond.paymentPeriodValue = paymentPeriodType.value;
        }
      });
      if (
        product.productBond.paymentPeriod &&
        product.productBond.paymentPeriod.name
      ) {
        product.productBond.paymentPeriod =
          product.productBond.paymentPeriod.name;
        product.productBond.paymentPeriodValue =
          product.productBond.paymentPeriod.value;
      }
    } else {
      if (
        product.type === Constant.CODE_FIXED_BOND ||
        product.type === Constant.CODE_FLEXIBLE_BOND
      ) {
        product.productBond = {};
      }
    }
  }
  static calculateBondTerm(maturityDate, endDate, noMonth?, noAdjust?) {
    if (typeof maturityDate !== "number" && typeof endDate !== "number") {
      return;
    }
    let bondTerm: any = "";
    let date = new Date();
    date = new Date(
      date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear()
    );
    let numberDate = (maturityDate - date.getTime()) / 1000 / 60 / 60 / 24;
    if (!noAdjust) {
      bondTerm = ConvertDataService.decimalAdjust(
        "round",
        numberDate / 30.42,
        -1
      );
      if (!noMonth) {
        bondTerm += " tháng ";
      }
      return bondTerm;
    } else {
      bondTerm = ConvertDataService.decimalAdjust(
        "round",
        numberDate / 30.42,
        -2
      );
      return bondTerm;
    }
  }
}
