import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, Input, OnChanges, Injector } from '@angular/core';
import { fadeInAnimation, fadeInOut } from '../../../common/service/routerTransition';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppQueries } from 'src/app/state';
import { EventManagerService } from 'src/app/common/service/event-manager.service';
import { Router } from '@angular/router';
import { SessionStorageService } from 'ngx-webstorage';

/**
 * declare Jquery
 * */
declare let $: any;

@Component({
    selector: 'industry-distribution',
    templateUrl: './industry-distribution.html',
    styleUrls: ['./industry-distribution.scss'],
    animations: [fadeInAnimation, fadeInOut],
    host: { '[@fadeInAnimation]': '' }
})

export class IndustryDistribution implements OnInit, AfterViewInit {
    @Input('industryDistribution') industryDistribution;
    @Input('updateTime') updateTime;
    isDevice: any = false;
    constructor(
        public eventManager: EventManagerService,
        public appQueries: AppQueries,
        public router: Router,
        public sessionStorage: SessionStorageService,
        public activeModal: NgbActiveModal) {
    }

    ngOnInit() {
        this.appQueries.isDevice$.subscribe((isDevice) => {
            this.isDevice = isDevice;
        });
    }
    ngAfterViewInit(): void {
        const totalWidth = Math.floor($('#container-industry-distribution')[0].offsetWidth) / 2 - 20;
        // const unitWidth = totalWidth / 100;
        let min = 100, max = 0;
        this.industryDistribution.forEach((item) => {
            if (min > item.assetPercent) {
                min = item.assetPercent;
            }
            if (max < item.assetPercent) {
                max = item.assetPercent;
            }
        });
        if (max !== min) {
            const unit = Math.floor(max / min);
            const unitWidth =  (totalWidth - 50) / (unit + 1);
            this.industryDistribution.forEach((item) => {
                item.width =  Math.floor((item.assetPercent / min) * unitWidth) + 'px';
            })
        } else {
            const unitWidth = totalWidth / 100;
            this.industryDistribution.forEach((item) => {
                item.width = (item.assetPercent * unitWidth) + 'px';
            })
        }
    }
}
