import {Injectable} from '@angular/core';
import {Constant} from "../common/constant";
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class RoboService {

  constructor(
    private http: HttpClient,
  ) {}

  getToken() {
    return this.http.post(environment.urlBackEnd + Constant.api_robo_get_token, {});
  }
  getResource() {
    return this.http.get(environment.urlRobo + Constant.api_robo_get_all);
  }
  getBeforeResult() {
    return this.http.post(environment.urlRobo + Constant.api_robo_load_resource, {});
  }
  getResult(body) {
    return this.http.post(environment.urlRobo + Constant.api_robo_get_result_survey, body);
  }
  checkSuccess() {
    return this.http.post(environment.urlBackEnd + Constant.api_robo_success_survey, {});
  }
}
