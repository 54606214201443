import { Pipe, PipeTransform } from '@angular/core';
import * as moment from "moment-timezone";
import { DateUtil } from '../utils/date.util';

const TIME_ZONE = "Asia/Ho_Chi_Minh";

@Pipe({ name: 'date_vn' })
export class TransformCustomDatePipe implements PipeTransform {
  transform(value: number, typeformart?: any) {
    return this.parseDate(value, typeformart);
  }
  parseDate(date, formart?) {
    date = DateUtil.revertDate(moment.tz(new Date(date), TIME_ZONE).format('MM/DD/yyyy, HH:mm'), 'MM/dd/yyyy, hh:mm');
    // date.setHours(date.getHours() + (new Date().getTimezoneOffset() / 60) + 7);
    const tt = date.getDay();
    let weekDay = '';
    switch (tt) {
      case 0:
        weekDay = 'chủ nhật';
        break;
      case 1:
        weekDay = 'thứ 2';
        break;
      case 2:
        weekDay = 'thứ 3';
        break;
      case 3:
        weekDay = 'thứ 4';
        break;
      case 4:
        weekDay = 'thứ 5';
        break;
      case 5:
        weekDay = 'thứ 6';
        break;
      case 6:
        weekDay = 'thứ 7';
        break;
    }
    const day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate();
    const month = date.getMonth() > 8 ? (date.getMonth() + 1) : '0' + (date.getMonth() + 1);
    const year = date.getYear() + 1900;
    const hour = date.getHours() > 9 ? date.getHours() : '0' + date.getHours();
    const minute = date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes();
    const second = date.getSeconds() > 9 ? date.getSeconds() : '0' + date.getSeconds();
    if (!formart) {
      return `${day}/${month}/${year}`;
    } else {
      let result = formart;
      result = result.replace(/tt/g, weekDay);
      result = result.replace(/dd/g, day);
      result = result.replace(/MM/g, month);
      result = result.replace(/yyyy/g, year);
      result = result.replace(/hh/g, hour);
      result = result.replace(/HH/g, hour);
      result = result.replace(/mm/g, minute);
      result = result.replace(/ss/g, second);
      return result + '';
    }
  }
}