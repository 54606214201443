<div style="padding: 0 15px;" class="otp-popup">
  <div class="row d-flex justify-content-center">
    <div class="col-lg-12 otp">
      <div class="otp-container">
        <!-- <span class="title" *ngIf="!isRegister">Xác thực</span> -->
        <div class="otp-container_form" [ngClass]="isRegister ? 'pb-3' : ''">
          <div class="form-group">
            <span *ngIf="!isSmartOTP">{{'Vui lòng nhập mã xác thực (OTP) đã được gửi đến
              số điện thoại' | translate}} <strong>(+{{phonePostal}}) {{phone}}</strong> {{'hoặc Zalo' | translate}}
            </span>
            <span *ngIf="isSmartOTP">{{'Vui lòng nhập mã' | translate}} <strong>Smart OTP</strong> {{'lấy từ ứng dụng
              fmarket trên thiết bị
              đã đăng ký' | translate}} </span>
          </div>
          <div class="form-group" *ngIf="note">
            <span>{{'Chú ý' | translate}}: </span><span>{{note}}</span>
          </div>
          <div class="form-group">
            <input-number [maxLength]="6" [isInteger]="true" [isSeparate]="false"
                [(amount)]="otp" *ngIf="countdown != 0">
            </input-number>
            <span class="error-text text-center mt-3" *ngIf="isInvalidOTP && countdown != 0">
              <img src="./assets/images/icon/notice-red.svg" alt="">
              {{errorText || 'OTP không hợp lệ. Vui lòng thử lại' | translate}}</span>
          </div>
          <div class="form-group group-text" *ngIf="!isSmartOTP">
            <span>{{'Mã OTP sẽ hết hạn trong vòng' | translate}} <span class="required">{{countdown}}s</span></span>
            <a (click)="resendOTP()" prevent-multi-clicks *ngIf="countdown === 0" class="udl">{{'Gửi lại OTP' | translate}}</a>
          </div>
          <div class="form-group">
            <button type="submit" class="btn btn-primary btn-login " [ngClass]="getClass()" [disabled]="!validate()"
              (click)="confirm()" prevent-multi-clicks >{{'Xác thực' | translate}}
            </button>
            <a class="button-close" (click)="dismiss()" *ngIf="!isRegister">{{'Đóng' | translate}}</a>

            <!-- XACTHUCDKY -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>