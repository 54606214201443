import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, Input, Injector, OnChanges, SimpleChanges } from '@angular/core';
import { fadeInAnimation, fadeInOut } from '../../../common/service/routerTransition';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { EventManagerService } from 'src/app/common/service/event-manager.service';
import { CopyProperties } from 'src/app/common/decorator/copy-properties.decorator';
@Component({
    selector: 'detail-fee',
    templateUrl: './detail-fee.html',
    styleUrls: ['./detail-fee.scss'],
    animations: [fadeInAnimation, fadeInOut],
    host: { '[@fadeInAnimation]': '' }
})

export class DetailFee  implements OnInit, OnDestroy, OnChanges {
    @Input('option') option :OptionFee;
    @Input('fees') fees;
    CODES_UNIT = [{code: 'MONTH', name: 'Theo tháng'}, {code: 'DAY', name: 'Theo ngày'} , {code: 'MONEY', name: 'Theo tiền'}];
    constructor(
        private eventManager: EventManagerService,
        public activeModal: NgbActiveModal,
        public modalService: NgbModal,) {
    }
    ngOnInit() {
    }
    ngOnChanges(changes: SimpleChanges) {
        if (changes.fees.currentValue) {
          const fees = changes.fees.currentValue || [];
          const firstFee = fees[0] || {};
          let valueUnit =  this.option.valueUnit || 'VND';
          if (firstFee.feeUnitType) {
            valueUnit = (this.CODES_UNIT.find(item => item.code === firstFee.feeUnitType) || {name: ''}).name;
          }
          this.option.valueUnit = valueUnit; 
        }
      }
    ngOnDestroy() {
    }
    dismiss() {
        this.activeModal.dismiss();
    }
}
@CopyProperties()
export class OptionFee {
    valueTitle: string;
    valueUnit: string;
    feeTitle: string;
    feeUnit: string;
    constructor(params?: any) {
        this.valueTitle = '';
        this.valueUnit = '';
        this.feeTitle = '';
        this.feeUnit = '';
    }
}