import { CopyProperties } from '../decorator/copy-properties.decorator';
import { ProductInfo } from './product.info.model';

@CopyProperties()
export class Product {
  id: number;
  code: String;
  subCode: String;
  name: String;
  shortName: String;
  price: number;
  approveAt: any;
  releaseAt: any;
  expireAt: any;
  closeAt: any;
  buyMin: any;
  buyMax: any;
  sellMin: any;
  inStock: any;
  website: any;
  createAt: any;
  updateAt: any;
  productInfo: ProductInfo;
  statusCode: any;
  typeCode: any;
  productFeeList: any[];
  productFeeDiscountList: any[];
  extra: any;
  productAssetAllocationModelList: any[];
  feeBuys: any[];
  feeSells: any[];
  normalTimeHolds: any[];
  type: any;
  isProductIpo: boolean;
  isNewProduct: boolean;
  isOnlySellMinNotSellAll: boolean;
  assets: any;
  endIssueAt: any;
  status: any;
  closedBankNote: any;
  closedOrderBookAt: any;
  expectedReturn: any;
  managementFee: any;
  nav: number;
  holdingMin: number;
  lastYearNav: number;
  completeTransactionDuration: any;
  moneyTransferSyntax: any;
  moneyTransferSyntaxId: any;
  transactionDate: any;
  productTransactionDateList: any;
  riskLevel: any;
  issuePrice: any;
  comment: any;
  buyMinValue: any;
  buyMaxValue: any;
  transferSellMin: any;
  performanceFee: number;
  owner : any;
  bank: any;
  productFund: any;
  tradeCode: String;
  sipCode: string;
  description: string;
  selectedDay: any;
  tradingTimeString: string;
  closedOrderBookString: string;
  closedBankNoteString: string;
  productTradingSession: any;
  customField: any;
  customValue: any;
  dataFundAssetType: any;
  fundAssetType: string;
  productNavChange: any;
  productTopHoldingList: any;
  productTopHoldingBondList: any;
  productAssetHoldingList: any;
  productIndustriesHoldingList: any;
  productDocuments: any;
  constructor(
    params?: any,
  ) {
    this.id = 0;
    this.subCode = '';
    this.code = '';
    this.name = '';
    this.shortName = '';
    this.price = 0;
    this.approveAt = null;
    this.releaseAt = null;
    this.expireAt = 0;
    this.closeAt = 0;
    this.buyMin = 0;
    this.buyMax = 0;
    this.sellMin = 0;
    this.inStock = 0;
    this.website = null;
    this.createAt = 0;
    this.updateAt = 0;
    this.productInfo = new ProductInfo();
    this.statusCode = null;
    this.typeCode = null;
    this.productFeeList = [];
    this.productFeeDiscountList = [];
    this.extra = null;
    this.tradeCode = '';
    this.sipCode = '';
    this.feeBuys  =  [];
    this.feeSells  =  [];
    this.feeBuys = [];
    this.type  =  null;
    this.isProductIpo  =  false;
    this.isNewProduct = false;
    this.isOnlySellMinNotSellAll = false;
    this.endIssueAt  =  new Date();
    this.status  =  '';
    this.closedBankNote  =  '';
    this.closedOrderBookAt  = '';
    this.expectedReturn  =  0;
    this.managementFee  =  0;
    this.nav  =  0;
    this.holdingMin  =  0;
    this.lastYearNav  =  0;
    this.completeTransactionDuration  =  '';
    this.moneyTransferSyntax  =  null;
    this.moneyTransferSyntaxId  =  0;
    this.transactionDate  =  null;
    this.productTransactionDateList  =  [];
    this.riskLevel  =  null;
    this.issuePrice  =  0;
    this.comment  =  '';
    this.buyMinValue  =  0;
    this.buyMaxValue  =  0;
    this.transferSellMin  =  0;
    this.performanceFee  =  0;
    this.owner = null;;
    this.description = '';
    this.selectedDay = null;
    this.tradingTimeString = '';
    this.closedOrderBookString = '';
    this.closedBankNoteString = '';
    this.fundAssetType = '';
    this.bank = null;
    this.productFund = null;
    this.productAssetAllocationModelList = [];
    this.normalTimeHolds = [];
    this.productTradingSession = null;
    this.customField = null;
    this.customValue = null;
    this.dataFundAssetType = null;
    this.productNavChange = null;
    this.productTopHoldingList = [];
    this.productTopHoldingBondList = [];
    this.productAssetHoldingList = [];
    this.productIndustriesHoldingList = [];
    this.productDocuments = [];
    this.assets = '';
  }
}
