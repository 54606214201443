import { HttpParams } from '@angular/common/http';

export const createRequestParams = (request?: any): HttpParams => {
  const req = request ? executeParams(request) : request;
  if (req.page) {
    req.page = req.page;
  }
  let params: any = {};
  if (req) {
    Object.keys(req).forEach(key => {
      if (key !== 'sort' && (req[key] || req[key] === 0) && key !== 'searchParams') {
        params[key] =  req[key];
      }
    });
    // if (req.sort) {
    //   req.sort.forEach(val => {
    //     params['sort'] =  val;
    //   });
    // }
    req.searchParams && Object.keys(req.searchParams).forEach(key => {
      if ((req.searchParams[key] || req.searchParams[key] === 0)) {
        if (typeof req.searchParams[key] === 'string') {
          req.searchParams[key] = req.searchParams[key].trim();
        }
        params[key] =  req.searchParams[key];
      }
    });
  }
  return params;
};
export const createRequestOption = (req?: any): HttpParams => {
  let options: HttpParams = new HttpParams();
  if (!req) {
      return options;
  }
  Object.keys(req).forEach(key => {
    if (key !== 'sort' && key !== 'searchParams' && req[key] !== undefined && req[key] !== null) {
      options = typeof req[key] === 'string' ? options.set(key, (req[key] +'').trim()) : options.set(key, req[key]);
    }
  });
  // if (req.sort) {
  //   req.sort.forEach(val => {
  //     options = options.append('sort', val);
  //   });
  // }
  req.searchParams = req.searchParams || {};
  Object.keys(req.searchParams).forEach(key => {
    if (req.searchParams[key]) {
      options = options.set(key, req.searchParams[key]);
    }
  });
  return options;
};

export const executeParams = (params?: any) => {
  if (!params.searchParams) {
    return params;
  }
  const keys = Object.keys(params.searchParams);
    keys.forEach((key) => {
      if (typeof params.searchParams[key] === 'string') {
        params.searchParams[key] = params.searchParams[key].trim();
        if (params.searchParams[key] === 'undefined') {
          params.searchParams[key] = undefined;
        }
      }
    });
  return JSON.parse(JSON.stringify(params));
};
