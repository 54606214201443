import { CopyProperties } from "../../decorator/copy-properties.decorator";

@CopyProperties()
export class TabNav {
    name: string;
    code: string;
    link: string;
    id: string;
    constructor(
        params?: any
    ) {
        this.name = '';
        this.code = '';
        this.link = '';
        this.id = '';
        
    }
}