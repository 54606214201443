import { State, Action, StateContext, Actions, ofActionSuccessful } from '@ngxs/store';
import { StoreState, StoreInfoUser, StoreToken, GetInfoUser, OpenOTP, SetDataOTP, SetTimeOTP, StoreTheme, StoreDataSource, GetDataSource, SetIsDevice } from './app.actions';
import { Injectable } from '@angular/core';
import { Dispatcher } from './base/dispatcher';
import { UserService } from '../common/service/user.service';
import { UserProfile } from '../common/models/users.model';
import { AuthorizeService } from '../main/services/authorize.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { NewOTPComponent } from '../common/components/otp/otp.component';
import { LocalStorageService } from 'ngx-webstorage';
import { EventManagerService } from '../common/service/event-manager.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { DataService } from '../common/service/data.service';
import { Constant } from '../common/constant';
declare let $: any;
export interface AppStateModel {
    states: any;
    userProfile: any;
    token: string;
    theme: any;
    dataSource: any;
    otp: any;
    isDevice: boolean;
}

export function initAppState(): AppStateModel {
    return {
        userProfile: new UserProfile(),
        states: []
    } as AppStateModel;
}

@State<AppStateModel>({
    name: 'App',
    defaults: initAppState()
})
@Injectable()
export class AppState {
    callApi$ = new Subject();
    constructor(
        private actions$: Actions,
        private dispatcher: Dispatcher,
        private dataService: DataService,
        private userService: UserService,
        private modalService: NgbModal,
        private localStorage$: LocalStorageService,
        public eventManger: EventManagerService,
        private authorService: AuthorizeService
    ) {
        this.callApi$.pipe(
            debounceTime(500),
            ).subscribe((event)=> { 
              this.getProfileUser(event);
            });
    }

    @Action(StoreState)
    StoreTag(ctx: StateContext<AppStateModel>, action: StoreState) {
        const states = action.payload;
        ctx.patchState({
            states
        });
    }
    @Action(StoreToken)
    StoreToken(ctx: StateContext<AppStateModel>, action: StoreToken) {
        const token = action.payload;
        ctx.patchState({
            token
        });
    }
    @Action(StoreTheme)
    StoreTheme(ctx: StateContext<AppStateModel>, action: StoreTheme) {
        const theme = action.payload;
        ctx.patchState({
            theme
        });
    }
    @Action(StoreDataSource)
    StoreDataSource(ctx: StateContext<AppStateModel>, action: StoreDataSource) {
        const dataSource = action.payload;
        dataSource.phonePostalCodes.forEach((item) => {
            item.callingCode = item.callingCode + '';
        })
        ctx.patchState({
            dataSource
        });
    }
    @Action(StoreInfoUser)
    StoreInfoUser(ctx: StateContext<AppStateModel>, action: StoreInfoUser) {
        const userProfile = action.payload;
        this.authorService.setAuthorities(userProfile);
        ctx.patchState({
            userProfile
        });
    }
    @Action(GetInfoUser)
    GetInfoUser(ctx: StateContext<AppStateModel>, action: GetInfoUser) {
        const isFirst = action.payload;
        this.callApi$.next(isFirst);
    }
    @Action(GetDataSource)
    GetDataSource(ctx: StateContext<AppStateModel>, action: GetDataSource) {
        return this.dataService.getDataCommon();
    }
    getProfileUser(isFirst) {
        return this.userService.getProfileAPI()
        .subscribe((res: any) => {
            if (res.status === Constant.success_code) {
              const profile =  res.data;
              profile.isFirst = isFirst;
              const user = new UserProfile(profile);
              user.setProfile(profile);
              user.isFullInformation = UserService.checkFullInformation(user);
              this.dispatcher.fire(new StoreInfoUser(user));
              UserService.storeProfile(user);
            } else {
            }
        }, (error) => {
        })
    }
    @Action(OpenOTP)
    OpenOTP(ctx: StateContext<AppStateModel>, action: OpenOTP) {
        const otp = Object.assign({}, ctx.getState().otp || {});
        const payload =  Object.assign({},action.payload || {});
        const user = this.localStorage$.retrieve('USER_PROFILE') || {};
        const options: NgbModalOptions = {
            backdrop : 'static',
            keyboard : false
        };
        options.centered = true;
        options.windowClass  = 'otp-order-label';
        const modalRef = this.modalService.open(NewOTPComponent, options);
        const properties = payload.isReopen ? otp : payload;
        modalRef.componentInstance.phone = payload.phone || user.phone;
        Object.keys(properties || {}).forEach((key) => {
            modalRef.componentInstance[key] = properties[key] || {};
        });
        if (!payload.isReopen) {
            Object.keys(properties || {}).forEach((key) => {
                otp[key] = properties[key] || {};
            });
            otp.startTime = Date.now();
            otp.failTimes = 0;
            modalRef.componentInstance.startTime = otp.startTime;
        }
        ctx.patchState({
            otp
        });
    }
    @Action(SetTimeOTP)
    SetTimeOTP(ctx: StateContext<AppStateModel>, action: SetTimeOTP) {
        const otp = Object.assign({}, ctx.getState().otp || {});
        const time = action.payload;
        otp.startTime = time;
        ctx.patchState({
            otp
        });
    }
    @Action(SetDataOTP)
    SetDataOTP(ctx: StateContext<AppStateModel>, action: SetDataOTP) {
        const otp = Object.assign({}, ctx.getState().otp || {});
        const payload = action.payload;
        Object.keys(payload).forEach((key) => {
            otp[key] = payload[key];
        });
        this.eventManger.broadcast({
            name: 'set-data-otp',
            content: {
                data: Object.assign({}, otp.data || {}),
                func: otp.func
            }
          })
        ctx.patchState({
            otp
        });
    }
    @Action(SetIsDevice)
    SetIsDevice(ctx: StateContext<AppStateModel>, action: SetIsDevice) {
        const isDevice = action.payload;
        ctx.patchState({
            isDevice
        });
    }
}
