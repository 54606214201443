import {Injectable} from '@angular/core';
import {Constant} from "../common/constant";
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthService {

  constructor(
    private http: HttpClient,
    private localStorage: LocalStorageService, 
    private sessionStorage: SessionStorageService
  ) {}

  login(data) {
    return this.http.post(environment.urlBackEnd + Constant.api_auth_sign_in,data)
    .pipe(map((res: any) => {
      if (!res.data.mustLogin2Steps) {
        let status = res.data.profile.status;
        //Check status user is pending
        if (status !== Constant.status_user_pending) {
          //Save infor user
          const token = res.data.tokenType + " " + res.data.accessToken;
          this.localStorage.store('TOKEN', token);
          this.sessionStorage.store('TOKEN', token);
        }
      }
      return res;
    }))
  }
  testLogin(data) {
    return this.http.post(environment.urlBackEnd + Constant.api_auth_sign_in,data)
    .pipe(map((res: any) => {
      const token = res.data.tokenType + " " + res.data.accessToken;
      return token;
    }))
  }
  getAccessCode() {
    return this.http.get(environment.urlBackEnd + '/users/get-access-code');
  }
  clear() {
    this.localStorage.store('TOKEN', '');
    this.sessionStorage.store('TOKEN', '');
  }
  confirmOTP(body) {
    return this.http.post(environment.urlBackEnd + Constant.api_auth_sign_in_confirm, body);
  }
  checkExist(body) {
    return this.http.post(environment.urlBackEnd + Constant.api_security_otp_validate, body);
  }
  signUp(body) {
    return this.http.post(environment.urlBackEnd + Constant.api_security_otp_sign_up, body);
  }
  confirmOTPRegister(body) {
    return this.http.post(environment.urlBackEnd + Constant.api_security_otp_confirm_sign_up, body);
  }
  forgotPassword(data) {
    return new Promise<boolean>((resolve, reject) =>{
      this.http.post(environment.urlBackEnd + Constant.api_auth_forgot_pass,data)
        .subscribe( (res: any)=>{
          if (res.status === Constant.success_code) {
              resolve(true)
          }
        })
    })
  }
  resendOTP(body) {
    return this.http.post(environment.urlBackEnd + Constant.api_security_otp_resend, body);
  }
  resendMail(data) {
    return new Promise<boolean>((resolve, reject) =>{
      this.http.post(environment.urlBackEnd + Constant.api_auth_renew_registration_confirm,data)
        .subscribe((res: any)=>{
          if (res.status === Constant.success_code) {
            resolve(true)
          }
        })
    })
  }
}
