import { Injectable } from "@angular/core";
import { SessionStorageService } from 'ngx-webstorage';
import { Constant } from 'src/app/common/constant';
import { DataService } from 'src/app/common/service/data.service';
import { Dispatcher, StoreTheme } from 'src/app/state';

/**
 * declare Jquery
 * */
declare let $: any;

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  constructor(
    private dispatcher: Dispatcher,
    private dataService: DataService,
    private sessionStorageService: SessionStorageService
  ) {
  }

  getTheme() {
    this.dataService.subData()
      .then((res: any) => {
        if (!res) {
          return;
        }
        const subDomainOwner: any = this.sessionStorageService.retrieve('subDomainOwners');
        subDomainOwner.colorObj = subDomainOwner.colorObj || '';
        if (!subDomainOwner.colorObj.includes('{') || !subDomainOwner.colorObj.includes('}')) {
          subDomainOwner.colorObj = '{}';
        }
        const colorObj = JSON.parse(subDomainOwner.colorObj || '{}') || {};
        if (colorObj.backgroundColor) {
          document.documentElement.style.setProperty("--primary-color", colorObj.backgroundColor);
        }
        if (colorObj.fontColor) {
          document.documentElement.style.setProperty("--font-color", colorObj.fontColor);
        }
        if (colorObj.defaultButonColor) {
          document.documentElement.style.setProperty("--default-button-color", colorObj.defaultButonColor);
        }
        if (colorObj.primaryButtonColor) {
          document.documentElement.style.setProperty("--primary-button-color", colorObj.primaryButtonColor);
        }
        if (colorObj.successButtonColor) {
          document.documentElement.style.setProperty("--success-button-color", colorObj.successButtonColor);
        }
        if (colorObj.warningButtonColor) {
          document.documentElement.style.setProperty("--warning-button-color", colorObj.warningButtonColor);
        }
        if (colorObj.dangerButtonColor) {
          document.documentElement.style.setProperty("--danger-button-color", colorObj.dangerButtonColor);
        }
        if (colorObj.hoverColor) {
          document.documentElement.style.setProperty("--hover-color", colorObj.hoverColor);
        }
        if (subDomainOwner.backgroundUrl) {
          document.documentElement.style.setProperty("--background-url", `url(${subDomainOwner.backgroundUrl}) no-repeat top`);
        }
        subDomainOwner.textObj = subDomainOwner.textObj || '';
        if (!subDomainOwner.textObj.includes('{') || !subDomainOwner.textObj.includes('}')) {
          subDomainOwner.textObj = `{"footer": "Đầu tư quỹ mở Daiichi life thông qua fmarket là đại lý phân phối chính thức"}`;
        }
        const textObj = JSON.parse(subDomainOwner.textObj || '{}') || {};
        const imageObj = JSON.parse(subDomainOwner.imageObj || '{}') || {};
        const theme = {
          name: subDomainOwner.name,
          subDomainOwnerId: subDomainOwner.id,
          logo: subDomainOwner.logoUrl || Constant.logo_url_logo_white,
          backgroundUrl: subDomainOwner.backgroundUrl,
          textChange: textObj,
          imageChange: imageObj,
          isDefault: false
        }
        this.dispatcher.fire(new StoreTheme(theme));
      })
  }
}