import { Component, Input, OnInit, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { Chart } from 'chart.js';
import { Constant } from '../../constant';
import { NumberUtil } from '../../utils/number.util';

@Component({
  selector: 'app-chart-js-line-chart',
  templateUrl: './chart-js-line-chart.component.html',
  styleUrls: ['./chart-js-line-chart.component.scss']
})
export class ChartJSLineChartVerticalComponent implements OnInit, OnChanges, OnDestroy {
  @Input() height: number = 350;
  @Input() noLabels: boolean = false;
  @Input() noBorderGrid: boolean = false;
  isReload: boolean = false;
  isDevice: boolean = false;
  id: number = Date.now();
  public chart: any;
  width: any;

  @Input() chartData: any = {
    labels: [''],
    datasets: [
      {
        label: '',
        data: [13],
        borderColor: '',
        backgroundColor: '',
        borderWidth: 2,
        pointStyle: 'cross',
        pointBorderWidth: 0,
        pointBackgroundColor: '',
      }
    ]
  };
  chartColorRed: any = {
    borderColor: 'rgb(7,46,105)',
    backgroundColor: 'rgba(7,46,105, 0.2)',
    pointBackgroundColor: 'rgb(7,46,105)',
  
    borderWidth: 1,
    fill: false,
    cubicInterpolationMode: 'monotone',
    tension: 0.4
  };
  chartColorBlue: any = {
    borderColor: 'rgb(3,104,255)',
    backgroundColor: 'rgba(3,104,255, 0.2)',
    pointBackgroundColor: 'rgb(3,104,255)',
    borderWidth: 0,
    pointBorderWidth: 0,
    pointHitRadius: 0,
    fill: false,
    cubicInterpolationMode: 'monotone',
    tension: 0.4
  };
  constructor() {}
  ngOnInit() {
    if (typeof window !== 'undefined') {
      this.width = window.innerWidth;
      this.isDevice = Constant.checkAndroid() || Constant.checkIOS() ? true : false;
    }
  }
  ngOnChanges(simpleChanges: SimpleChanges) {
    const changes: any = simpleChanges;
    if (changes.chartData.currentValue) {
      setTimeout(() => {
        this.createChart();
      }, 1000);
    }
  }
  ngOnDestroy(): void {
    if (this.chart) {
      this.chart.destroy();
    }
  }
  createChart() {
    if (this.chart) {
      this.chart.destroy();
    }
    this.chartData.datasets.forEach((item: any) => {
      if (item.isColorBlue) {
        item.borderColor = this.chartColorBlue.borderColor;
        item.backgroundColor = this.chartColorBlue.backgroundColor;
        item.pointBackgroundColor = this.chartColorBlue.pointBackgroundColor;

        if (item.isFill) {
          item.fill = {
            target: 'origin',
            above: this.chartColorBlue.backgroundColor,   // Area will be red above the origin
            // below: 'yellow'    // And blue below the origin
          };
        }
      }
      if (item.isColorRed) {
        item.borderColor = this.chartColorRed.borderColor;
        item.backgroundColor = this.chartColorRed.backgroundColor;
        item.pointBackgroundColor = this.chartColorRed.pointBackgroundColor;
        if (item.isFill) {
          item.fill = {
            target: 'origin',
            above: this.chartColorRed.backgroundColor,   // Area will be red above the origin
            // below: 'yellow'    // And blue below the origin
          };
        }
      }
      item.pointStyle = 'line';
      item.pointBorderWidth = 0;
      // item.fill = false;
      // item.cubicInterpolationMode = 'monotone';
      // item.tension =  0.4;
    });
    this.chartData.labels = this.noLabels ? this.chartData.labels.map((item: any) => {return '';}) : this.chartData.labels;
    this.chart = new Chart('lineChart' + this.id, {
      type: 'line',
      data: this.chartData,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: this.isDevice ? 0.85 : 2.5,
        scales: {
          y: {
            grace: '5%',
            beginAtZero: false,
            grid: {
              color: this.noBorderGrid ?  'white' : 'rgba(0, 0, 0, 0.1)'
            }
          },
          x: {
            grid: {
              color: this.noBorderGrid ?  'white' : 'rgba(0, 0, 0, 0.1)'
            }
          }
        },
        plugins: {
          tooltip: {
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            bodyFont: {
              size: 14
            },
            titleFont: {
              size: 16,
              weight: 'bold'
            },
            callbacks: {
              label: (context) => {
                let label = context.dataset.label || '';

                if (label) {
                    label += ': ';
                }
                label +=  NumberUtil.numberToMoney(context.parsed.y || 0);
                label += ' Ngày: ' + this.chartData.dateLabels[context.dataIndex];
                return label;
              }
            }
          },
          legend: {
            display: false
          },
        }
      }
    });
  }
  improveXTrick(xAxisTicks: any, divide: number = 2) {
    if (xAxisTicks.length / divide >= (this.isDevice ? 4 : 8)) {
      xAxisTicks = xAxisTicks.filter((item: any, index: any) => index % divide === 0);
      xAxisTicks = this.improveXTrick(xAxisTicks);
    }
    return xAxisTicks;
  }
}
