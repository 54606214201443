<div class="tab-content-1">
  <div class="row">
    <div class="col-12 mt-3" *ngIf="listProspectus?.length">
      <div class="row modal-row modal-heading">
        <div class="col-8 tt-buy-hgt">
          <label class="title-fee">{{'Bản cáo bạch' | translate}}</label>
          <br>
        </div>
        <div class="col-4 col-sm-4 tt-buy-hgt">
        </div>
      </div>
      <div class="row row-color" *ngFor="let item of listProspectus">
        <div class="col-8 tt-buy-hgt no-padding-right">
          <a [href]="item.url" target="_blank">{{item.fileName}}</a>
        </div>
        <div class="col-4 tt-buy-hgt text-right">
          <label class="label-name text-left">{{item.applyAt}}</label>
        </div>
      </div>
    </div>

    <div class="col-12 mt-3" *ngIf="listRegulation?.length">
      <div class="row modal-row modal-heading">
        <div class="col-8 tt-buy-hgt">
          <label class="title-fee">{{'Điều lệ quỹ' | translate}}</label>
          <br>
        </div>
        <div class="col-4 col-sm-4 tt-buy-hgt">
        </div>
      </div>
      <div class="row row-color" *ngFor="let item of listRegulation">
        <div class="col-8 tt-buy-hgt no-padding-right">
          <a [href]="item.url" target="_blank">{{item.fileName}}</a>
        </div>
        <div class="col-4 tt-buy-hgt text-right">
          <label class="label-name text-left">{{item.applyAt}}</label>
        </div>
      </div>
    </div>

    <div   *ngIf="listRegulation?.length === 0  && listProspectus?.length === 0">
      <no-data></no-data>
    </div>

  </div>
</div>