import { Component, OnInit, Output, EventEmitter, Injector } from '@angular/core';
import { AppQueries } from 'src/app/state';
import { Constant } from '../../constant';
import { EventManagerService } from '../../service/event-manager.service';

@Component({
    selector: 'fmarket-footer',
    templateUrl: './footer.component.html',
    styleUrls: [
        'footer.scss',
    ]
})
export class FooterComponent implements OnInit {
    theme: any = {};
    language: string = 'VI';
    languageText: string = 'Tiếng Việt';
    Constant = Constant;
    constructor(
        private appQueries: AppQueries,
        private eventManagerService: EventManagerService
    ) {
        
    }
    ngOnInit() {
        this.appQueries.theme$
        .subscribe((theme) => {
          this.theme = theme;
        });
        const language = localStorage.getItem(Constant.key_local_language);
        this.changeLanguage(language);
    }
    getYear() {
        let date = new Date();
        return date.getFullYear();
    }
    changeLanguage(language) {
        this.eventManagerService.broadcast({
          name: 'change-language',
          content: {
            language
          }
        });
        this.language  = language;
        switch (this.language) {
            case 'VI':
                this.languageText = 'Tiếng Việt';
            break;
            case 'EN':
                this.languageText = 'English';
            break;
        }
    }
}
