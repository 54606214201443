<div class="row" id="container-industry-distribution" *ngIf="industryDistribution?.length; else noData">
  <div class="col-12 mt-3">
    <div class="row modal-row modal-heading">
      <div class="col-12 tt-buy-hgt">
        <label class="title-fee">{{'% Giá trị tài sản' | translate}}</label>
        <br>
      </div>
    </div>
    <div class="row row-color" *ngFor="let item of industryDistribution">
      <div class="col-5 col-sm-5 col-lg-5 tt-buy-hgt no-padding-right">
        <label>
          {{item.industry}}
        </label>
      </div>
      <div class="col-7 col-sm-7 col-lg-7 tt-buy-hgt">
        <div class="d-flex flex-column">
          <div class="d-flex align-items-center">
            <div class="bar mr-2" [style.width]="item.width"></div>
            <span>{{item.assetPercent}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-3" *ngIf="updateTime" [ngClass]="isDevice ? 'ml-3' : 'ml-1' ">
    <span>Theo báo cáo tháng {{updateTime}}</span>
  </div>
</div>
<ng-template #noData>
  <div class="block-nodata">
    <img src="./assets/images/icon/nodata.png" alt="">
    <h5>Phân bổ theo ngành trống</h5>
    <span>Không có dữ liệu</span>
  </div>
</ng-template>