<div class="chat-container" *ngIf="!thirdAppId">
  <div class="chat-button shadow phone" *ngIf="isLoadingPartner">
    <a href="tel:1900571299">
      <img class="" src="{{Constant.icon_url_toolchat}}" title="Hỗ trợ">
    </a>
  </div>
  <div class="chat-button messenger shadow" *ngIf="isLoadingPartner">
    <a target="_blank" href="https://m.me/fmarket.vn">
      <img alt="" class="reminder" width="30"
        src="https://s3-ap-southeast-1.amazonaws.com/finandesk-static/assets/images/icon/MESSENGER.svg">
      <span class="btn-tooltip" placement="left"></span>
    </a>
  </div>
  <div class="chat-button zalo shadow">
    <div class="zalo-chat-widget" data-oaid="4075079027959830010" data-autopopup="0" data-width="400" data-height="530"
      data-welcome-message="0"></div>
  </div>
</div>
