import {Injectable} from '@angular/core';
import {Constant} from "../common/constant";
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class RewardService {

  constructor(
    private http: HttpClient,
  ) {}

  getReward() {
    return this.http.post(environment.urlBackEnd + Constant.api_investors_get_reward, {});
  }
  getIntroduceInvestor(body) {
    return this.http.post(environment.urlBackEnd + Constant.api_investors_get_reward_introduce_investor, body);
  }
  getQRCode(body) {
    return this.http.post(environment.urlBackEnd + Constant.api_investors_get_qr_code_reward, body);
  }
}
