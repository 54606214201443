import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from "@angular/core";
import { TabNav } from "./tab.model";
/**
 * declare Jquery
 * */
declare let $: any;

@Component({
  selector: 'tab-list',
  templateUrl: "./tab-list.component.html",
  styleUrls: ['./tab-list.component.scss'],
})

export class TabListComponent implements OnInit, OnChanges  {
    @Input() list: TabNav[] = [];
    @Input() isNoRoute: boolean = false;
    @Output() tabChange: EventEmitter<any> = new EventEmitter();
    tab: string = '';
    id: number = Date.now();
    constructor(
    ) {
      
    }
    ngOnInit() {
    }
    ngOnChanges(changes: SimpleChanges): void {
      if (changes.list && changes.list.currentValue) {
        if (this.isNoRoute) {
          this.tab = (this.list[0] || {}).code;
        } 
      }
    }
    
    changeTab(tabCode) {
      this.tab = tabCode;
      this.tabChange.emit(this.tab);
    }
}
