import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import {Constant} from "./common/constant";
import {NotFoundComponent} from "./common/components/notFound/notFound.component";
import { MaintenanceComponent } from './common/components/maintenance/maintenance.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  { path: Constant.path_account, loadChildren: () => import('./main/main.module').then(m => m.MainModule)},
  { path: '', loadChildren: () => import('./authorization/auth.module').then(m => m.AuthModule) },
  { path: Constant.path_auth, loadChildren: () => import('./authorization/auth.module').then(m => m.AuthModule) },
  { path: Constant.path_active, loadChildren: () => import('./active-mail/active-mail.module').then(m => m.ActiveMailModule) },
  { path: Constant.path_share, loadChildren: () => import('./share-public/share-public.module').then(m => m.SharePublicModule) },
  { path: '503', component: MaintenanceComponent },
  { path: '**', component: NotFoundComponent }
];

export const AppRouting = RouterModule.forRoot(routes, {
  preloadingStrategy: PreloadAllModules,  // <- comment this line for activate lazy load
  // useHash: true
});

