import {Injectable} from '@angular/core';
import {Constant} from "../constant";
import {TranslateService} from "@ngx-translate/core";
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Subscription, Subject, Observable } from 'rxjs';
import { SessionStorageService } from 'ngx-webstorage';
import { Dispatcher, StoreDataSource } from 'src/app/state';

@Injectable({ providedIn: 'root' })
export class DataService {
  populateData$ = new Subject();
  isLoad: boolean = false;
 

  public token: string = localStorage.getItem(Constant.key_local_token);

  public currentLang: string = localStorage.getItem(Constant.key_local_language) ? localStorage.getItem(Constant.key_local_language) : Constant.default_language;

  public fundAssetTypes: Array<any> = [];

  public countries: Array<any> = [];
  
  public phonePostalCodes: Array<any> = [];
  
  public agents: Array<any> = [];

  public fundSchemes: Array<any> = [];

  public issuerTypes: Array<any> = [];
  
  public moneyTransferSyntax: Array<any> = [];

  public relationalOperators: Array<any> = [];

  public fundTypes: Array<any> = [];

  public moneyTypes: Array<any> = [];

  public banks: Array<any> = [];

  public socialTypes: Array<any> = [];

  public interestRate: Array<any> = [];

  public provinces: Array<any> = [];

  public placeOfIssueIds: Array<any> = [];

  public idTypes: Array<any> = [];
  
  public paymentPeriodTypes: Array<any> = [];

  public daysOfWeek: Array<any> = [];
  
  public riskLevels: Array<any> = [];

  public subDomainOwners: Array<any> = [];

  public introduceAgents: Array<any> = [];

  public isSetData :boolean = false;

  public id :any = null;

  public sourceInfos: any = [];

  public dataState = new Subject<any>();

  public configReward: any = {};

  public thirdApps: any = {};

  constructor(
    private http : HttpClient,
    private dispatcher: Dispatcher,
    private sessionStorageService: SessionStorageService
  ) {
  }
  applyThis(data) {
    Object.keys(data).forEach((key) => {
      if (this[key] !== undefined) {
        this[key] = data[key];
      }
    })
  }
  setData(data) {
    this.isSetData = true;
    this.interestRate = data.reinvestmentInterests || data.interestRate;
    this.fundAssetTypes = data.fundAssetTypes;
    if (this.daysOfWeek[0] && this.daysOfWeek[0].id != 0) {
      this.daysOfWeek.unshift({
        id: 0,
        name: 'Thứ Hai đến Thứ Sáu'
      })   
    }
    data.phonePostalCodes = data.phonePostalCodes.map((item, index) => {
      item.id = index;
      item.name = item.countryCode + ' (+' + item.callingCode + ')';
      return item;
    });
    data.supportInfo = data.supportInfo.map((item) => {
      var name = item.name;
      item = item.socialType;
      item.name = name;
      if (item.code  === Constant.FACEBOOK || item.code  === Constant.KAKAOTALK
        || item.code  === Constant.YOUTUBE || item.code  === Constant.TWITTER) {
        item.sanitize =  item.name;
      }
      else if (item.code === Constant.MESSENGER) {
        item.sanitize = item.name.indexOf('http://') > 0 ? item.name : 'https://' + item.name;
      } else if (item.code  === Constant.SKYPE) { 
        item.sanitize = ('skype:' + item.name + '?chat')
      } else if (item.code  === Constant.VIBER) {
        item.sanitize = ('viber://contact?number=' + this.getName(item.name)) //thay add bằng contact để chạy trên iOS
      } else if (item.code  === Constant.ZALO) {
        item.sanitize = 'https://zalo.me/' + item.name;
      } else if (item.code  === Constant.WECHAT) {
        item.sanitize = 'weixin://dl/profile=' + item.name;
      } else if (item.code  === Constant.WHATSAPP) {
        item.sanitize = 'https://wa.me/' + item.name;
      } else { 
        item.sanitize = (item.code.toLowerCase() + '://send?phone=' + this.getName(item.name))
      }
      return item;
    })
    return data;
  }
  getName(name) {
    if (Constant.checkAndroid()) {
      return  name.substring(1,name.length)
    } else return name
  }
  getFull() {
    return this;
  }
  subData() {
    return new Promise((resolve, reject) => {
      let interval = setInterval(() => {
        if (this.isSetData) {
          clearInterval(interval);
          resolve(this.getFull());
        }
      }, 200)
    })
  }
  getState(): Observable<any> {
    return this.dataState.asObservable();
  }
  public getDataCommon() {
    this.isLoad = true;
    const commonData = JSON.parse(sessionStorage.getItem(Constant.key_local_common_data) || 'null');
    if (commonData) {
      this.executeCommonData(commonData);
      return;
    } else {
      this.http.get(environment.urlBackEnd + Constant.api_common)
      .subscribe((res: any) => {
        this.isLoad = false;
        if (res.status !== Constant.success_code) {
          return;
        }
        const data = this.setData(res.data);
        this.executeCommonData(data);
      })
    }
  }
  executeCommonData(data) {
    sessionStorage.setItem(Constant.key_local_common_data,JSON.stringify(data));
    this.dataState.next(true);
    this.isSetData = true;
    const subDomainOwner = ((data || {}).subDomainOwners  || []).find((item) => {
      let hostname = location.hostname;
      hostname.replace('www.', '');
      return item.subDomain.includes(hostname);
    }) || {};
    this.sessionStorageService.store('subDomainOwners', JSON.parse(JSON.stringify(subDomainOwner)));
    const nameNormalProgram = (data.fundSchemes.find(item => item.code === 'NORMAL') || {}).name;
    localStorage.setItem('nameNormalProgram', nameNormalProgram);
    const nameNormalSIP = (data.fundSchemes.find(item => item.code === 'SIP') || {}).name;
    localStorage.setItem('nameNormalSIP', nameNormalSIP);
    this.dispatcher.fire(new StoreDataSource(data));
    this.applyThis(data);
  }
}
