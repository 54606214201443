<div class="modal-content animate-bottom">
  <div class="modal-header">
      <h5 class="modal-title" id="exampleModalLabel">{{'Hướng dẫn thanh toán bằng QR code' | translate}}</h5>
      <button type="button" class="btn close-btn" (click)="dismiss()">
          <img src="./assets/images/icon/ic-black-close.svg" alt="">
      </button>
  </div>
  <div class="modal-body">
      <ul>
          <li><strong>1.</strong> {{'Nhấn vào' | translate}} <strong>"{{'Tải về' | translate}}"</strong>
              {{'để tải hình QR và lưu trên điện thoại của bạn.' | translate}}
          </li>
          <li><strong>2.</strong> {{'Mở ứng dụng ngân hàng' | translate}} <strong>(Mobile Banking)</strong>
              {{'của bạn và chọn chức năng QR Code.' | translate}}</li>
          <li><strong>3.</strong> {{'Chọn' | translate}} <strong>{{'hình QR đã tải về' | translate}}</strong>
              , {{'đối chiếu thông tin và thực hiện chuyển khoản.' | translate}}
          </li>
      </ul>
  </div>
</div>