<div class="row">
  <div class="col-12 mt-3">
    <div class="row modal-row modal-heading">
      <div class="col-7 col-sm-7 tt-buy-hgt">
        <label class="title-fee">{{'Tỉ lệ' | translate}}</label>
        <br>
      </div>
      <div class="col-2 col-sm-2 tt-buy-hgt">
        <label class="title-fee">{{'SSIBF' | translate}}</label>
        <br>
      </div>
      <div class="col-3 col-sm-3 text-right tt-buy-hgt">
        <label class="title-fee">{{'VN-Index' | translate}}</label>
        <br>
      </div>
    </div>
    <div class="row row-color">
      <div class="col-7 col-sm-7 tt-buy-hgt no-padding-right">
        <label>
          {{'Chỉ số P/E (x)*' | translate}}
        </label>
      </div>
      <div class="col-2 col-sm-2 tt-buy-hgt">
        <label class="label-name">12.8</label>
      </div>
      <div class="col-3 col-sm-3 text-right tt-buy-hgt">
        <label class="label-name">9.6</label>
      </div>
    </div>
    <div class="row row-color">
      <div class="col-7 col-sm-7 tt-buy-hgt no-padding-right">
        <label>
          {{'Vòng quay danh mục 12 tháng (%)' | translate}}
        </label>
      </div>
      <div class="col-2 col-sm-2 tt-buy-hgt">
        <label class="label-name">25.0</label>
      </div>
      <div class="col-3 col-sm-3 text-right tt-buy-hgt">
        <label class="label-name">9.6</label>
      </div>
    </div>
  </div>
</div>