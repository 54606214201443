import { CopyProperties } from '../decorator/copy-properties.decorator';
@CopyProperties()
export class ProductInfo {
    id: number;
    dayCompleteTransaction: number;
    managementFee: number;
    expectedProfit: number;
    firstReleaseAt: any;
    firstReleasePrice: any;
    fundType: String;
    minimumAverageAnnualReturn: number;
    supervisionBank: String;
    investmentStrategyCode: String;
    reportPeriodicalCode: String;
    investingAccount: any;
    gain12m: number;
    gain36m: number;
    risk12m: number;
    avgProfit: number;
    performanceFee: number;

    constructor(
        params?: any
    ) {
        if (params) {
            this.id = params.id || null;
            this.dayCompleteTransaction = params.dayCompleteTransaction || 0;
            this.managementFee = params.managementFee || 0;
            this.expectedProfit = params.expectedProfit || 0;
            this.firstReleaseAt = params.firstReleaseAt || null;
            this.firstReleasePrice = params.firstReleasePrice || null;
            this.fundType = params.fundType || null;
            this.minimumAverageAnnualReturn = params.minimumAverageAnnualReturn || 0;
            this.supervisionBank = params.supervisionBank || null;
            this.investmentStrategyCode = params.investmentStrategyCode || null;
            this.reportPeriodicalCode = params.reportPeriodicalCode || null;
            this.investingAccount = params.investingAccount || null;
            this.gain12m = params.gain12m || 0;
            this.gain36m = params.gain36m || 0;
            this.risk12m = params.risk12m || 0;
            this.avgProfit = params.avgProfit || 0;
            this.performanceFee =  params.performanceFee || 0;
        } else {
            this.id = null;
            this.dayCompleteTransaction = 0;
            this.managementFee = 0;
            this.expectedProfit = 0;
            this.firstReleaseAt = null;
            this.firstReleasePrice = null;
            this.fundType = null;
            this.minimumAverageAnnualReturn = 0;
            this.supervisionBank = null;
            this.investmentStrategyCode = null;
            this.reportPeriodicalCode = null;
            this.investingAccount = null;
            this.gain12m = 0;
            this.gain36m = 0;
            this.risk12m = 0;
            this.avgProfit = 0;
            this.performanceFee =  0;
        }
        
    }
}