import { AfterViewInit, Component, OnDestroy, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Constant } from "../../constant";
import { EventManagerService } from '../../service/event-manager.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DateUtil } from 'src/app/common/utils/date.util';
/**
 * declare Jquery
 * */
declare let $: any;

@Component({
    selector: 'success-popup',
    templateUrl: "./success-popup.html",
    styleUrls: ["./success-popup.scss"]
})

export class SuccessPopupComponent implements OnInit {
    public Constant = Constant;
    @Output() result: EventEmitter<any> = new EventEmitter();
    @Input() title: any;
    @Input() message: any;
    @Input() isOkButton: any;
    @Input() titleButton: any;
    @Input() iconImage: any = Constant.icon_url_success;
    // @Input() iconImage: any;
    @Input() isDismissAll: boolean;
    @Input() properties;
    @Input() imgClass;
    constructor(
        public eventManger: EventManagerService,
        public activeModal: NgbActiveModal,
        public ngModal: NgbModal
    ) { }
    ngOnInit() {
        // console.log('open toast: ', DateUtil.parseDate(Date.now(), 'hh:mm:ss'));
        if (this.properties) {
            Object.keys(this.properties).forEach((key) => {
                this[key] = this.properties[key];
            });
        }
    }
    dismiss() {
        if (!this.isDismissAll) {
            this.activeModal.dismiss();
        } else {
            this.ngModal.dismissAll();
        }
        this.result.emit(true);
    }
}
