<!--Pagination-->
<nav aria-label="pagination example" *ngIf="total && totalPage > 1">
  <ul class="pagination pg-blue justify-content-end">
    <!--Arrow left-->
    <li class="page-item" *ngIf="totalPage <= 5">
      <span class="page-link" (click)="totalPage > 1 && first()">
        <!-- Trang Đầu -->
        <i class="fa fa-step-backward" aria-hidden="true" title="Trang đầu"></i>
      </span>
    </li>
    <li class="page-item" *ngIf="totalPage > 5" [ngClass]="selectedPage === 1 ? 'disabled-button' : ''">
      <span class="page-link" (click)="changePage(selectedPage - 5)">
        <i aria-hidden="true" class="fa fa-step-backward"></i>
      </span>
    </li>
    <li class="page-item">
      <span class="page-link" [ngClass]="!checkPrevious() ? 'disabled-button' : ''" (click)="previous()">
        <i class="fa fa-caret-left fa-lg" aria-hidden="true"></i>
      </span>
    </li>
    <li class="page-item" *ngFor="let item of pages; index as index">
      <span class="page-link" (click)="changePage(item)"
        [ngClass]="item === selectedPage ? 'active-link background-active-link' : ''">
        {{item}}
        <span class="sr-only">(current)</span>
      </span>
    </li>
    <li class="page-item">
      <span class="page-link" [ngClass]="!checkNext() ? 'disabled-button' : ''" (click)="next()">
        <i class="fa fa-caret-right fa-lg" aria-hidden="true"></i>
      </span>
    </li>
    <li class="page-item" *ngIf="totalPage > 5" [ngClass]="selectedPage === totalPage ? 'disabled-button' : ''">
      <span class="page-link" (click)="changePage(selectedPage + 5)">
        <i aria-hidden="true" class="fa fa-step-forward"></i>
      </span>
    </li>
    <li class="page-item" *ngIf="totalPage <= 5">
      <span class="page-link" (click)="totalPage > 1 && last()" title="Trang cuối">
        <!-- Trang Cuối -->
        <i class="fa fa-step-forward" aria-hidden="true"></i>
      </span>
    </li>
  </ul>
</nav>