<div id="app" class="app" [ngClass]="settings.theme">
  <div
    [ngClass]="{
      mobile: Constant.checkMobile() && !Constant.checkTablet(),
      tablet: Constant.checkTablet()
    }"
  >
    <router-outlet></router-outlet>
    <!-- chat box -->
  </div>
</div>

<div id="overlay" style="display: none"></div>
<chatting></chatting>
<loading-modal
  [style.min-height]="minHeight"
  *ngIf="isShowLoading"
></loading-modal>
<!-- <p *ngIf="(online$ | async) == false" >
  <span [ngClass]="noticeOffline()"></span>
</p> -->
<img
  [src]="Constant.logo_no_connect_internet"
  width="20"
  height="20"
  style="display: none"
/>

<img
  id="fileUpload"
  src="./assets/images/background/front-card.png"
  width="20"
  height="20"
  style="display: none"
/>
<img
  src="./assets/images/background/loading-fmarket-white.svg"
  style="display: none"
/>
<img src="./assets/images/background/loading-icon.svg" style="display: none" />

<!-- <section class="scan-qr">
  <figure>
    <img src="./assets/images/homepage/qr-download.png" alt="qr-code" />
    <figcaption>
      <h4>Quét mã QR để tải ứng dụng Fmarket</h4>
    </figcaption>
    <div class="scan"></div>
  </figure>
</section> -->
