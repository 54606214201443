import { Component, Input, OnInit, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { Chart } from 'chart.js';
import { Constant } from '../../constant';

@Component({
  selector: 'app-chart-js-pie-chart',
  templateUrl: './chart-js-pie-chart.component.html',
  styleUrls: ['./chart-js-pie-chart.component.scss']
})
export class ChartJsPieChartComponent implements OnInit, OnChanges, OnDestroy {
  @Input() height: number = 250;
  @Input() noLabels: boolean = false;
  @Input() noBorderGrid: boolean = false;
  isReload: boolean = false;
  isDevice: boolean = false;
  id: number = Date.now();
  public chart: any;
  width: any;

  @Input() chartData: any = {
    labels: [''],
    datasets: [
      {
        label: '',
        data: [300, 50, 100],
        backgroundColor: [
          'rgb(255, 99, 132)',
          'rgb(54, 162, 235)',
          'rgb(255, 205, 86)'
        ],
        hoverOffset: 4
      }
    ]
  };
  constructor() {}
  ngOnInit() {
    if (typeof window !== 'undefined') {
      this.width = window.innerWidth;
      this.isDevice = Constant.checkAndroid() || Constant.checkIOS() ? true : false;
    }
  }
  ngOnChanges(simpleChanges: SimpleChanges) {
    const changes: any = simpleChanges;
    if (changes.chartData.currentValue) {
      setTimeout(() => {
        this.createChart();
      }, 1000);
    }
  }
  ngOnDestroy(): void {
    if (this.chart) {
      this.chart.destroy();
    }
  }
  createChart() {
    if (this.chart) {
      this.chart.destroy();
    }
    this.chartData.datasets.forEach((item: any) => {
      console.log(item);
    });
    this.chartData.labels = this.noLabels ? this.chartData.labels.map((item: any) => {return '';}) : this.chartData.labels;
    this.chart = new Chart('pieChart' + this.id, {
      type: 'doughnut',
      data: this.chartData,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: this.isDevice ? 0.85 : 2.5,
        plugins: {
          legend : {
            display: false
          }
        }
      },
    });
  }
}
