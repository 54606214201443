import { AfterViewInit, Component, OnDestroy, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EventManagerService } from 'src/app/common/service/event-manager.service';
import { fadeInAnimation, fadeInOut } from 'src/app/common/service/routerTransition';
import { Constant } from 'src/app/common/constant';
import { Subscription } from 'rxjs';
import { InforPayment } from '../info-payment/info-payment';
import { AppQueries } from 'src/app/state';
import { SessionStorageService } from 'ngx-webstorage';
declare let $: any;
@Component({
    selector: 'payment-info-mobile',
    templateUrl: './payment-info-mobile.html',
    styleUrls: [
        './payment-info-mobile.scss'
    ],
    animations: [fadeInAnimation, fadeInOut],
    host: { '[@fadeInAnimation]': '' }
})

export class PaymentInfoMobile implements OnInit, OnDestroy {
    email: string;
    Constant = Constant;
    @Input('paymentInfo') paymentInfo;
    @Input('transaction') transaction;
    @Input('isContributeGoal') isContributeGoal: boolean = false;
    @ViewChild('infopayment', { static: true }) infopayment: InforPayment;
    @Output() result: EventEmitter<any> = new EventEmitter();
    isDevice: any = false;
    subscriptions?: Subscription[] = [];
    isNormal: boolean = true;
    constructor(
        public modalService: NgbModal,
        public router: Router,
        public eventManagerService: EventManagerService,
        private $sessionStorage: SessionStorageService,
        private appQueries: AppQueries,
        public activeModal: NgbActiveModal) {
    }
    ngOnInit() {
        this.subscriptions = [
            this.appQueries.dataSource$
            .subscribe((dataSource) => {
              const resources = dataSource || { thirdApps: {data: []}};
              const thirdAppId = this.$sessionStorage.retrieve('thirdAppId');
              const byWoori = !!(resources.thirdApps.data || []).find(t => t.id === +thirdAppId && t.code === 'woori');
              const byTnex = !!(resources.thirdApps.data || []).find(t => t.id === +thirdAppId && t.code === 'tnex');
              this.isNormal = !byWoori && !byTnex;
            }),
            this.appQueries.isDevice$.subscribe((isDevice) => {
                this.isDevice = isDevice;
            })
        ];
    }
    ngOnDestroy() {
        this.eventManagerService.destroys(this.subscriptions);
    }
    confirm() {
        if (this.isContributeGoal) {
            this.eventManagerService.broadcast({
              name: 'contribute-goal-confirm-payment',
              content: {
                transaction: this.transaction,
              }
            });
            return;
        }
        this.infopayment.confirm();
    }
    dismiss() {
        this.activeModal.dismiss();
    }
}