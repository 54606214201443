import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'paging',
  templateUrl: './new-paging.component.html',
  styleUrls: ['./new-paging.component.scss']
})
export class NewPagingComponent implements OnInit, OnChanges {
  @Input() total: number;
  @Input() size: number;
  @Input() selectedPage: number;
  @Input() isChangePageSize: boolean;
  @Output() changePage$: EventEmitter<any> = new EventEmitter();
  @Output() changeSize$: EventEmitter<any> = new EventEmitter();
  totalPage: number;
  pages: number[] = [];
  sizes: any[]  = [{size: 10, name: '10'},{size: 20, name: '20'},{size: 50, name: '50'},
    {size: 100, name: '100'}];
  ngOnInit() {
  }
  setUp(isChangeSize?) {
    this.totalPage = Math.ceil((this.total || 0)/ this.size) || 1;
    this.pages = [];
    this.selectedPage = this.selectedPage || 1;
    for (let i = 0; i < this.totalPage; i++) {
      this.pages.push(i + 1);
    }
    this.setPages();
  }
  changePage(page) {
    this.selectedPage = page;
    if (this.selectedPage < 1) {
      this.selectedPage = 1;
    }
    if (this.selectedPage > this.totalPage) {
      this.selectedPage = this.totalPage;
    }
    this.changePage$.emit(this.selectedPage);
    this.setPages();
  }
  changeSize() {
    this.changeSize$.emit(this.size);
  }
  ngOnChanges(changes: SimpleChanges) {
    const isChangeSize = changes.size && changes.size.currentValue ? true : false;
    this.setUp(isChangeSize);
  }
  getClass(page) {
    let cls = '';
    if (page < this.selectedPage -2 || page > this.selectedPage + 2) {
      cls+= 'hidden-page ';
    }
    if (page === this.selectedPage) {
      cls+= 'active ';
    }
    return cls;
  }
  next() {
    this.selectedPage++;
    this.changePage$.emit(this.selectedPage);
  }
  previous() {
    this.selectedPage--;
    this.changePage$.emit(this.selectedPage);
  }
  checkPrevious() {
    if (this.selectedPage > 1) {
        return true;
    } else return false;
  }
  checkNext() {
    if (this.selectedPage < this.totalPage) {
        return true;
    } else return false;
  }
  first() {
    if (this.totalPage > 4) {
        this.setPages();
    } else {
        this.pages = [];
        for (let i = 0 ; i < this.totalPage; i++) {
            this.pages.push(i+1);
        }
    }
    this.changePage(1);
  }
  last() {
    this.changePage(this.totalPage);
  }
  setPages() {
    this.pages = [this.selectedPage];
    let before = 2, valueBefore = 1, after = 2, valueAfter = 1;

    for (let i = 0 ; i < 5; i++) {
        if (before > 0) {
            if (this.selectedPage - valueBefore > 0) {
                before--;
                this.pages.unshift(this.selectedPage - valueBefore)
                valueBefore++;
            } else {
                after+= before;
                before = 0;
            }
        }
        if (after > 0){
            if (this.selectedPage + valueAfter <= this.totalPage) {
                after--;
                this.pages.push(this.selectedPage + valueAfter)
                valueAfter++;
            } else {
                before+= after;
                after = 0;
            }
        }
    }
  }
}
