import {AfterViewInit, Component, OnDestroy, OnInit, Input, Output, EventEmitter} from "@angular/core";
import { Constant } from "../../constant";
import { ShortcutService } from "../../service/shortcut.service";
import { of } from 'rxjs';
import { EventManagerService } from '../../service/event-manager.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DateUtil } from 'src/app/common/utils/date.util';
/**
 * declare Jquery
 * */
declare let $: any;

@Component({
    selector: 'no-connect-internet-popup',
    templateUrl: "./no-connect-internet-popup.html",
    styleUrls: ["./no-connect-internet-popup.scss"]
})

export class NoConnectInternetComponent implements OnInit {
    public Constant = Constant;
    @Output() result: EventEmitter<any> = new EventEmitter();
    @Input() title: any;
    @Input() message: any;
    @Input() isOkButton: any;
    @Input() iconImage: any = Constant.logo_no_connect_internet;
    @Input() isDismissAll: boolean;
    constructor(
        public eventManger: EventManagerService,
        public activeModal: NgbActiveModal,
        public ngModal: NgbModal
    ) {}
    ngOnInit() {
        console.log('open toast: ', DateUtil.parseDate(Date.now(), 'hh:mm:ss'));
    }
    dismiss() {
        ShortcutService.removeFuncEsc();
        if (!this.isDismissAll) {
            this.activeModal.dismiss();
        } else {
            this.ngModal.dismissAll();
        }
        this.result.emit(true);
    }
}
