import {Injectable} from "@angular/core";

/**
 * declare Jquery
 * */
declare let $: any;

@Injectable()
export class MenuService {

  public isShow = false;
  public list: any = [];
  toglle() {
    $('#navbarNavDropdown').collapse('toggle');
    $('.navbar-header .navbar-toggle').toggleClass('active');
  }

  toglleDashboard() {
    $('#navbar').collapse('toggle');
    $('.navbar-header .navbar-toggle').toggleClass('active');
  }

  show() {
    this.isShow = true;
    $('#navbarNavDropdown').collapse('show');
    $('.navbar-header .navbar-toggle').removeClass('active');
  }
  hide() {
    this.isShow = false;
    $('#navbarNavDropdown').collapse('hide');
    $('.navbar-investor').removeClass('active');
    $('.navbar-header .navbar-toggle').removeClass('active');

  }

  hideDashboard() {
    this.isShow = false;
    $('#navbar').collapse('hide');
    $('#header, .navbar-header .navbar-toggle').removeClass('active');
    
  }

  setMenuList(list) {
    this.list = list.map((item) => {
      return {
        name: item,
        isShow: false
      }
    }) 
  }
  activeMenu(name) {
    this.list = this.list.map((item) => {
        if (item.name === name) {
          item.isShow = true;
        } else {
          item.isShow = false;
        }
        return item;
    })
  }
  getStatusMenu(name) {
    let result = false; 
    this.list.forEach((item) => {
      if (item.name === name) {
        result = item.isShow;
      }
    })
    return result;
  }
}
