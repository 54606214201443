import { Product } from './product.model';
import { CopyProperties } from '../decorator/copy-properties.decorator';
@CopyProperties()
export class TradingFund extends  Product {
    tradingTimeString: string;
    closedOrderBookString: string;
    closedBankNoteString: string;
    constructor(
        params?: any
    ) {
        super(params);
        this.tradingTimeString = '';
        this.closedOrderBookString = '';
        this.closedBankNoteString = '';
        
    }
}