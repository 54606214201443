import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, Input, OnChanges, Injector } from '@angular/core';
import { fadeInAnimation, fadeInOut } from '../../../common/service/routerTransition';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppQueries } from 'src/app/state';
import { EventManagerService } from 'src/app/common/service/event-manager.service';
import { Router } from '@angular/router';
import { ProductService } from 'src/app/product/product.service';
import { DetailProductFunction } from '../../../main/components/detail-product.abstract.function';
import { Product } from 'src/app/common/models/product.model';
import { SessionStorageService } from 'ngx-webstorage';

/**
 * declare Jquery
 * */
declare let $: any;

@Component({
    selector: 'detail-ipo-fund',
    templateUrl: './detail-ipo-fund.html',
    styleUrls: ['./detail-ipo-fund.scss'],
    animations: [fadeInAnimation, fadeInOut],
    host: { '[@fadeInAnimation]': '' }
})

export class DetailIPOFund extends DetailProductFunction implements OnInit, OnChanges, OnDestroy {
    isDevice: any = false;
    isPendingIPO: any = false;
    product: Product = new Product();
    constructor(
        public eventManager: EventManagerService,
        public appQueries: AppQueries,
        public router: Router,
        public sessionStorage: SessionStorageService,
        public activeModal: NgbActiveModal,
        public productService: ProductService,) {
        super(eventManager, appQueries, router, sessionStorage, activeModal, productService)
    }

    initData() {
        super.initData();
        this.isPendingIPO = (this.product.productFund || {}).ipoStatusCode === 'PROCESSING_AFTER_BUY';
    }
}
