<!-- Popup thông tin chi tiết CCQ đang giao dịch -->
<div class="modal-contents new-fund">

  <!-- Modal Header -->
  <!-- <div class="modal-header">
    <div class="row">
      <div class="col-12 col-sm-8 col-md-8 col-lg-8">
        <label>
          Thông tin vàng
        </label>
      </div>
    </div>
  </div> -->

  <!-- Modal body -->
  <div class="modal-body horizon-mobile" style="min-height: 340px;">
    <div class="gold__info" *ngIf="product?.id; else loadData">
      <div class="gold__img" *ngIf="!isDevice">
        <img [src]="product?.productGold?.imageUrl" alt=""
        (error)="$event.target.src = Constant.icon_url_gold_demo">

      </div>
      <div class="gold__detail w-100">
        <span class="title">{{product?.name}}</span>
        <span class="sub-title" *ngIf="!isDevice">{{product?.owner?.name}}</span>
        <span class="sub-title" *ngIf="isDevice">{{product?.owner?.shortName}}</span>
        <hr *ngIf="isDevice">
        <div class="gold__img" *ngIf="isDevice" [ngClass]="isDevice ? 'gold__img-mobile' : ''">
          <img [src]="product?.productGold?.imageUrl" alt=""
          (error)="$event.target.src = Constant.icon_url_gold_demo">

        </div>
        <p class="content">
          {{product?.description}}
        </p>
        <button class="btn btn--blue" [ngClass]="isDevice ? 'w-100' : 'w-30'" type="button" (click)="buy()" prevent-multi-clicks>
          {{'Mua' | translate}}
        </button>
      </div>
    </div>
    <ng-template #loadData>
      <div class="d-flex align-items-center justify-content-center" style="height: 300px;">
        <img src="./assets/images/background/loading-icon.svg" height="134" width="134">
      </div>
    </ng-template>
    <button type="button" class="btn btn__close" (click)="dismiss()">
      <img src="./assets/images/icon/ic-close-modal.svg" alt="">
    </button>
  </div>