import {Injectable, OnInit} from '@angular/core';
import {Constant} from "../constant";
import { HttpClient } from '@angular/common/http';
import { ROUTE_INVESTOR} from '../route.url';
import { UserProfile } from '../models/users.model';
import { AppQueries, Dispatcher, StoreInfoUser } from 'src/app/state';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EventManagerService } from './event-manager.service';
import { createRequestOption } from '../utils/request-util';

@Injectable({
  providedIn: 'root'
})
export class UserService implements OnInit  {
  public user: UserProfile = new UserProfile();
  public authenticationState = new Subject<any>();
  static isFullInformation: boolean = false;
  static user: UserProfile = new UserProfile();
  constructor(
    private appQueries: AppQueries,
    private http: HttpClient,
    private eventManager: EventManagerService,
    private dispatcher: Dispatcher
  ) {
  }
  ngOnInit() {
    this.appQueries.userProfile$
    .subscribe((userProfile) => {
      this.user = userProfile;
      this.authenticationState.next(new UserProfile(userProfile));
    })
  }
  static storeProfile(userProfile) {
    this.user = userProfile;
    this.isFullInformation = this.user.isFullInformation;
  }
  static getProfile() {
    return this.user;
  }
  clear() {
    this.dispatcher.fire(new StoreInfoUser(new UserProfile()));
  }
  getProfileAPI() {
    return this.http.get(environment.urlBackEnd +  Constant.api_user_profile);
  }
  checkHealthAPI() {
    const body = {
      interestRate: 10,
      year: 10,
      investmentAmountPerMonth: 10000000,
      initialAmount: 0,
    };
    return  this.http.post(environment.urlBackEnd + Constant.api_common_calcualte_invest, body);
  }
  getFlagUpdateName() {
    return  this.http.get(environment.urlBackEnd +  Constant.api_user_get_flag_update_name);
  }
  updateName(body) {
    return  this.http.put(environment.urlBackEnd +  Constant.api_user_update_name, body);
  }
  checkAuthorUrl(route: any) {
    // let url = route._routerState.url;
    // let listChildren= [];
    // if (this.user.userType === Constant.INVESTOR) {
    //   listChildren = ROUTE_INVESTOR || [];
    // } 
    // return listChildren.some(children => children.path.includes(url) || url.includes(children.path) );
    return true;
  }
  firstUrl() {
    let route: any;
    if (this.user.userType === Constant.INVESTOR) {
      route = ROUTE_INVESTOR[0];
    } 
    return route  ? route.path : '';
  }
  validateEKYC(req) {
    const options = createRequestOption(req);
    return  this.http.get(environment.urlBackEnd + Constant.api_user_validate_ekyc, {params: options});
  }
  static  checkFullInformation(user?) {
    if (!user) {
      return false;
    }
    if (!this.checkInformationBank(user)) {
      return false;
    }
    if (!this.checkInformationPersonal(user)) {
      return false;
    }
    return true;
  }
  static checkInformationPersonal(user?) {
    let information = user ? user : this;
    if (!information.address1 || (information.isPersonalInvestor &&  !information.address2) || !information.province.id  || !information.passportDateOfIssue 
      || (information.passportDateOfIssue && information.passportDateOfIssue.length < 8) || !information.passportPlaceOfIssue) {
        return false;
      }
    if (information.isPersonalInvestor) {
      if ((information.birthDate && information.birthDate.length < 8)  || !information.birthDate || !information.passport || (information.gender != 1 && information.gender != 0)) {
        return false;
      }
      if (information.isRequiredFatca && information.isFatca === null) {
        return false;
      }
    } else {
      if (!information.taxCode || !information.authorizedPerson) {
        return false;
      } 
    }
    return true;
  }
  static checkInformationBank(user?) {
    let information = user ? user : this;
    if (information.bankAccounts && information.bankAccounts.length > 0) {
      return true;
    }
    return false;
  }
}
