import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, Input, OnChanges, Injector } from '@angular/core';
import { fadeInAnimation, fadeInOut } from '../../../common/service/routerTransition';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppQueries } from 'src/app/state';
import { EventManagerService } from 'src/app/common/service/event-manager.service';
import { Router } from '@angular/router';
import { SessionStorageService } from 'ngx-webstorage';

@Component({
    selector: 'documents-fund',
    templateUrl: './documents-fund.html',
    styleUrls: ['./documents-fund.scss'],
    animations: [fadeInAnimation, fadeInOut],
    host: { '[@fadeInAnimation]': '' }
})

export class DocumentsFund implements OnInit {
    @Input('documents') documents;
    listProspectus: any[] = [];
    listRegulation: any[] = [];
    constructor(


        public eventManager: EventManagerService,
        public appQueries: AppQueries,
        public router: Router,
        public sessionStorage: SessionStorageService,
        public activeModal: NgbActiveModal) {
    }

    ngOnInit() {
        this.documents.forEach((document) => {
            switch (document.documentType.code) {
                case 'PROSPECTUS_FUND':
                case 'SUMMARY_PROSPECTUS_FUND':
                    this.listProspectus.push(document);
                break;

                case 'REGULATION_FUND':
                case 'SUMMARY_REGULATION_FUND':
                    this.listRegulation.push(document);
                break;
            }
        })
    }
}
