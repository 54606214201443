import { Injectable, Injector, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constant } from 'src/app/common/constant';
import { AuthService } from 'src/app/authorization/auth.service';
import { UserService } from 'src/app/common/service/user.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Dispatcher, StoreInfoUser } from 'src/app/state';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { DialogService } from 'src/app/common/service/dialog.service';
declare let $: any;
@Injectable({ providedIn: 'root' })
export class  AccountService  {
    constructor(
        private http: HttpClient,
        private router: Router,
        private dispatcher: Dispatcher,
        private authService: AuthService,
        private dialogService: DialogService,
        private localStorage: LocalStorageService, 
        private sessionStorage: SessionStorageService,
        private zone: NgZone,
        private userService: UserService) {
    }
    checkMail(body) {
        return this.http.post(environment.urlBackEnd + Constant.api_user_check_email_verification, body);
    }
    getReport(body) {
        return this.http.post(environment.urlBackEnd + Constant.api_get_report_products_filter, body);
    }
    checkTotal() {
        return this.http.get(environment.urlBackEnd + Constant.api_investors_check_total);
    }
    logout() {
        this.clearLocal();
        this.goToLogin();
    }
    goToLogin() {
        this.dialogService.destroy();
        $(document.body).css('padding-right','0');
        
        localStorage.setItem(Constant.key_local_login_sucess, "false");
        //Go to screen login;
        this.zone.run(() => {
            this.router.navigate([Constant.url_login]);
        });
    };
    clearLocal() {
        //Remove token when logout success
        this.dispatcher.fire(new StoreInfoUser({}));
        sessionStorage.removeItem(Constant.key_local_token);
        localStorage.removeItem(Constant.key_local_token);
        this.sessionStorage.store('TOKEN', '');
        this.localStorage.store('TOKEN', '');
        this.authService.clear();
    }
}
