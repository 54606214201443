import {
  Component,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
  TemplateRef,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
} from "@angular/core";
import { Subscription } from "rxjs";
import { EventManagerService } from "../../service/event-manager.service";
import DiacriticUtil from "../../utils/diacritic.util";
declare let $;
@Component({
  selector: "dropdown",
  templateUrl: "./dropdown-search.component.html",
  styleUrls: ["./dropdown-search.component.scss"],
})
export class DropdownSearchComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy
{
  @Input() title: string;
  @Input() list: any;
  @Input() disabled: any;
  @Input() isHaveContent = false;
  @Output() result: EventEmitter<any> = new EventEmitter();
  @Output() resultGroup: EventEmitter<any> = new EventEmitter();
  @Input() value: any = null;
  @Input() field: string = "name";
  @Input() key: string = "id";
  @Input() rowTemplate: TemplateRef<any>;
  @Input() name: any = "";
  @Input() multiple: any = false;
  @Input() isError: boolean = false;
  @Input() backgroundColor = "transparent";
  @Input() minWidth = "120px";
  @Input() minCharacter = 20;
  @Input() values: any[] = [];
  @Input() optionGroups: OptionGroup[] = [];
  defaultItem: any = { id: undefined };
  @Input() isNoAll: any = true;
  color: string = "";
  textSearch: string = "";
  valueShow: string = "";
  groups: any[] = [];
  openDropdownlist = false;
  eventSubscribers?: Subscription[] = [];
  id =
    "myDropdown" +
    Math.floor(Math.random() * 1000) +
    Math.floor(Math.random() * 999);
  constructor(private eventManager: EventManagerService, private cdf: ChangeDetectorRef) {}
  ngOnInit() {
    this.eventSubscribers = [
      this.eventManager.subscribe("selected-item", (res) => {
        if (res.content) {
          this.chooseValue(res.content);
        }
      }),
      this.eventManager.subscribe("body-click", (res) => {
        const event = res.content.event;
        if (
          $(event.target).closest("#" + this.id).length === 0 &&
          $(event.target).closest("#action" + this.id).length === 0
        ) {
          this.openDropdownlist = false;
          document.getElementById(this.id) &&
            document.getElementById(this.id).classList.remove("show");
        } else {
          this.openDropdownlist = true;
        }
      }),
    ];
  }
  ngAfterViewInit() {
    $("select").click(function (e) {
      e.preventDefault();
    });
    this.cutName();
  }
  ngOnDestroy(): void {
    this.eventManager.destroys(this.eventSubscribers);
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.list && changes.list.currentValue) {
      this.onChangeList(changes.list.currentValue);
    }
    if (changes.value) {
      this.onChangeValue();
    }
    if (changes.values) {
      this.onChangeValues();
    }
    if (changes.optionGroups) {
      this.onChangeOptionGroup();
    }
    this.cutName();
  }
  onChangeList(currentValue) {
    this.list = JSON.parse(JSON.stringify(currentValue || []));
    if (this.value || this.value === 0) {
      this.onChangeValue();
    } else if (this.values) {
      this.onChangeValues();
    } else if (this.optionGroups.length) {
      this.onChangeOptionGroup();
    } else {
      this.name = "";
    }
    this.cutName();
  }
  onChangeValue() {
    if (!this.value && this.value !== 0) {
      this.name = this.title;
    }
    if ((this.value || this.value === 0) && this.list && this.list.length > 0) {
      const find =
        this.list.find(
          (item) =>
            item[this.key] === this.value ||
            item[this.key] === this.value[this.key] ||
            item[this.field] === this.value[this.field]
        ) || {};
      this.name = find[this.field];
      this.color = find.color || find.colorCode || this.color;
    }
  }
  onChangeValues() {
    this.list = this.list || [];
    if (!this.multiple) {
      return;
    }
    if (!this.list.length) {
      this.name = this.values.map((item) => item[this.field]).join(", ");
      return;
    }
    const values = [];
    this.list.forEach((item) => {
      const isExist = this.values.find((value) => {
        return value[this.key] === item[this.key] || value === item[this.key];
      });
      if (isExist) {
        values.push(item);
      }
      item.isCheck = isExist ? true : false;
    });
    const removeItems = [];
    if (!this.list.length) {
      this.name = values.map((item) => item[this.field]).join(", ");
      return;
    }
    this.values.forEach((value) => {
      const index = this.list.findIndex((item) => {
        return value[this.key] === item[this.key] || value === item[this.key];
      });
      if (index === -1) {
        removeItems.push(index);
      }
    });
    removeItems.reverse();
    removeItems.forEach((index) => {
      this.values.splice(index, 1);
    });
    this.name = values.map((item) => item[this.field]).join(", ");
    if (values[0] !== this.values[0]) {
      this.result.emit(values);
    }
  }
  onChangeOptionGroup() {
    if (this.optionGroups.length > 0) {
      this.groups = [];
      this.optionGroups.forEach((option) => {
        const list =
          (this.list || []).filter((item) =>
            option.values.some((value) => value === item[option.field])
          ) || [];
        this.groups.push({
          option,
          list,
        });
      });
    }
  }
  cutName() {
    const defaultWidth = !$("#action" + this.id)[0]
      ? 200
      : $("#action" + this.id)[0].offsetWidth;
    const character =
      Math.floor(defaultWidth / (this.minCharacter * 7.5) - 0.1) *
      this.minCharacter; // 20 character = 150px
    this.name = this.name || "";
    this.valueShow =
      this.name.length >= character && character > 0
        ? this.name.substring(0, character - 3) + "..."
        : this.name;
    this.cdf.detectChanges();
  }
  open() {
    document.getElementById(this.id).classList.toggle("show");
    // document.getElementById('#backdrop').classList.toggle("show");
    $("#backdrop").toggleClass("show-backdrop");
    $("#backdrop").click(function (e) {
      $("#backdrop").removeClass("show-backdrop");
    });
  }
  filter() {
    const filter = this.textSearch.toUpperCase();
    if (!this.groups.length) {
      this.list.forEach((item) => {
        const text = (item[this.field] || "").toUpperCase();
        const text1 = DiacriticUtil.removeDiacritics(item[this.field] || "").toUpperCase();
        if (text.includes(filter) || text1.includes(filter) || !filter) {
          item.isHide = false;
        } else {
          item.isHide = true;
        }
      });
    } else {
      this.groups.forEach((group) => {
        group.isHide = true;
        group.list.forEach((item) => {
          const text = (item[this.field] || "").toUpperCase();
          const text1 = DiacriticUtil.removeDiacritics(item[this.field] || "").toUpperCase();
          if (text.includes(filter) || text1.includes(filter) || !filter) {
            group.isHide = false;
            item.isHide = false;
          } else {
            item.isHide = true;
          }
        });
      });
    }
    this.cdf.detectChanges();
  }
  chooseValue(value) {
    if (!this.multiple) {
      document.getElementById(this.id).classList.remove("show");
      this.value = value || {};
      this.name = this.value[this.field];
      this.color = this.value.color || this.value.colorCode  || this.color;
      this.result.emit(value);
    } else {
      const firstValue = this.values[0];
      if (firstValue && !firstValue.id) {
        this.values = this.list.filter((item) => item.isCheck);
      }
      const index = this.values.findIndex(
        (item) => item[this.key] === value[this.key]
      );
      if (index === -1) {
        value.isCheck = true;
        if (!this.values.length && !value[this.key]) {
          this.values = [value];
          this.list.forEach((item) => (item.isCheck = true));
        } else if (this.values.length && !value[this.key]) {
          this.values = [];
          this.list.forEach((item) => (item.isCheck = false));
        } else if (value[this.key]) {
          this.values.push(value);
        }
      } else {
        value.isCheck = false;
        this.values.splice(index, 1);
      }
      this.name = this.values.map((item) => item[this.field]).join(", ");
      this.result.emit(this.values);
    }
    this.cutName();
    $("#backdrop").removeClass("show-backdrop");
  }
  chooseGroup(group) {
    this.resultGroup.emit(group);
  }
}
export class OptionGroup {
  field: string;
  values: any[];
  title: string;
  icon: string;
  constructor(params?) {
    if (!params) {
      return;
    }
    this.field = params.field;
    this.values = params.values;
    this.title = params.title;
    this.icon = params.icon;
  }
}
