import { Injectable, OnInit } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import {environment} from '../../../environments/environment';
import { Constant } from '../constant';
import { concatMap, debounceTime, tap } from 'rxjs/operators';
import { EventManagerService } from '../service/event-manager.service';
import { retry, retryWhen, delay } from 'rxjs/operators';
import { throwError } from 'rxjs';
declare let $;
@Injectable({ providedIn: 'root' })
export class ExecuteBeforeRequestInterceptor implements HttpInterceptor {
  private readonly SERVER_API_URL: any;
  extra: any;
  quanlity: any = 0;
  Constant = Constant;
  listAcceptMultipleAPI: any = [];
  listNoLoadingAPI: any = [];
  listNoReloadAPI: any = ['auth/change-mail-otp/step2/confirm'];
  multipleAPIs: any = {};
  isLoad: boolean = false;
  modalRef: any;
  retryCount = 3;
  retryWaitMilliSeconds = 5000;
  showSpinner$ = new Subject();
  constructor(
    private eventManagerService: EventManagerService,
    private $localStorage: LocalStorageService,
    private $sessionStorage: SessionStorageService,
  ) {
    this.SERVER_API_URL = environment.urlBackEnd;
    this.showSpinner$.pipe(
      debounceTime(2000),
      ).subscribe((event)=> { return this.showSpinner(event);});
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpResponse<any>> {
    let splits = request.url.split('/');
    let api = '';
    splits.forEach((item, index) => {
        if (index > 2) {
          api += '/' + item;
        }
    })
    api = api.replace('/web', '');
    this.beforeRequest(api, request.method);
    if (!this.multipleAPIs[api] || (this.checkExistAPI(api) && this.multipleAPIs[api])) {
      this.updateCache(api, 1);
    } else {
      console.log('ignore api: ', api);
      this.hideSpinner();
      return of();
    }
    return next.handle(request)
      .pipe(
        tap(
          (res: HttpResponse<any>) => {
            if (!res.status) {
              return; 
            }
            this.hideSpinner();
            if (this.multipleAPIs[api] !== undefined) {
              this.updateCache(api, 0);
            }
            const version = localStorage.getItem("version");
            this.extra = res.body ? res.body.extra : null;
            if (this.extra) {
              if (version && this.extra.clientVersion != version) {
                const thirdAppId = this.$sessionStorage.retrieve('thirdAppId');
                if ((thirdAppId && !environment.production) || this.checkExistNoReloadAPI(api)) {
                  return;
                }
                localStorage.setItem("version", this.extra.clientVersion);
                console.log('after version: ', version);
                sessionStorage.setItem(Constant.key_local_common_data, '');
                location.reload();
              } else  if (!version) {
                  localStorage.setItem("version", this.extra.clientVersion);
              }
           }
          },
          (err: any) => {
            this.hideSpinner();
            if (this.multipleAPIs[api] !== undefined) {
              this.updateCache(api, 0);
            }
            return err;
          }
        ),
        retryWhen(error => 
          error.pipe(
            concatMap((error, count) => {
              console.log('retry - api: ', count + '-' + api);
              if (count < this.retryCount && !error.status) {
                this.eventManagerService.broadcast({
                  name: 'show-spinner',
                  content: {}
                })
                return of(error);
              }
              return throwError(error);
            }),
            delay(this.retryWaitMilliSeconds)
          )
        )
      )
  }
  checkExistAPI(api) {
    this.listAcceptMultipleAPI = (this.listAcceptMultipleAPI.length  ?  this.listAcceptMultipleAPI :  JSON.parse(sessionStorage.getItem('multipleAPIs'))) || [];
    return Object.keys(this.listAcceptMultipleAPI).some((key) => { 
      return api.includes(key);
    })
  }
  checkExistNoLoadingAPI(api, method?) {
    this.listNoLoadingAPI = (this.listNoLoadingAPI.length  ?  this.listNoLoadingAPI :  JSON.parse(sessionStorage.getItem('noLoadingAPIs'))) || [];
    return this.listNoLoadingAPI.some((item: any) => { 
      return api.includes(item.url) && (!item.method || (item.method && item.method === method));
    })
  }
  checkExistNoReloadAPI(api) {
    return this.listNoReloadAPI.some((item: any) => { 
      return item === api;
    })
  }
  updateCache(api, value) {
    const multipleAPIs = JSON.parse(sessionStorage.getItem('multipleAPIs')) || {};
    if (value || multipleAPIs[api]) {
      this.multipleAPIs[api] = value;
    } else {
      setTimeout(() => {
        this.multipleAPIs[api] = value;
      }, 500)
    }
  }
  private beforeRequest(api, method?): void {
    if (this.checkExistNoLoadingAPI(api, method)) {
      // console.log('no loading: ', api);
      return;
    }
    // show spinner
    this.quanlity++;
    // console.log('loading: ', api);
    this.showSpinner$.next(api);
    // load 30s hide spinner
    // setTimeout(() => {
    //   if (this.isLoad) {
    //     this.hideSpinner(true);
    //   }
    // }, 1000 * 30)
  }
  showSpinner(api) {
    if (this.isLoad || !this.quanlity) {
      return;
    }
    this.isLoad = true;
    // console.log('loading: ', api);
    this.eventManagerService.broadcast({
      name: 'show-spinner',
      content: {}
    })
  }
  hideSpinner(isForce?) {
    if (this.quanlity > 0) {
      this.quanlity--;
    }
    // close spinner
    if (isForce) {
      this.quanlity = 0;
    }
    if (this.quanlity == 0) {
      // console.log('close: ', new Date());
      this.isLoad = false;
      setTimeout(() => {
        this.eventManagerService.broadcast({
          name: 'close-spinner',
          content: {}
        })
      }, 1000)
    }
  }
}
