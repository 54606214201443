<div class="accordian-body" [ngClass]="isDevice ? '' : ''" [attr.id]="'info-payment-'+ info.id">
  <div class="col-12">
    <div class="row">
      <div class="col-lg-12 modal-header mb-4" *ngIf="!isDevice">
        <div class="order-info">
          <h5>{{'THÔNG TIN CHUYỂN KHOẢN' | translate}}</h5>
          <button type="button" class="close" (click)="dismiss()" title="Close"><img
              src="{{Constant.icon_url_close_modal}}"></button>
        </div>
      </div>
      <div class="col-12 modal-body"
        [ngClass]="!isDevice && info?.status !== Constant.ORDER_INIT && !thirdAppId ? 'fix-height' : '' ">
        <div class="row">
          <div class="col-lg-12">
            <div class="d-flex justify-content-between">
              <div class="title-left">
                <img
                  [src]="transaction?.product?.owner?.avatarUrl !== null ? transaction?.product?.owner?.avatarUrl : ' ' "
                  [ngClass]="transaction?.product?.owner?.avatarUrl === null ? 'hidden' : ''" alt=""
                  class="product-logo">
                <div class="d-flex flex-column">
                  <label>{{transaction?.product?.shortName}}</label>
                  <span>{{transaction?.product?.owner?.shortName}}</span>
                </div>
              </div>
              <div class="title-right">
                <label class="mb-0">{{'Ngày đặt lệnh' | translate}}
                </label>
                <span>{{transaction?.extra?.buyingDate | date_vn: 'dd/MM/yyyy, hh:mm'}}</span>
              </div>
            </div>
          </div>

          <div class="col-lg-12">
            <div class="block__info">
              <div class="col-lg-3 block__item">
                <label for="">{{'Số tiền thanh toán' | translate}}</label>
                <span>{{info.buyingValue}}</span>
              </div>
              <div class="col-lg-3 block__item">
                <label for="">{{'Chương trình' | translate}}</label>
                <span>{{transaction?.productProgram?.scheme?.name}}</span>
              </div>
              <div class="col-lg-4 block__item">
                <label for="">{{'Sổ lệnh đóng' | translate}}</label>
                <span>{{info?.isOrderBuyIpo ? (info?.ipoEndTime | date_vn: 'dd/MM/yyyy, hh:mm') :
                  info?.closedOrderBookString}}</span>
              </div>
              <div class="col-lg-2 block__item">
                <label for="">{{'Phiên khớp lệnh' | translate}}</label>
                <span>{{info?.isOrderBuyIpo ? 'Sau khi kết thúc IPO' : info?.tradingTimeString}}</span>
              </div>

            </div>
          </div>
          <div class="col-lg-12">
            <div class="row">
              <!-- <div class="col-lg-12" *ngIf="thirdAppId">
                <span class="payment__title">{{'Chọn phương thức thanh toán để hoàn tất thanh toán' | translate}}</span>
              </div> -->
              <div *ngIf="thirdAppId" class="col-lg-12 col-md-12 mb-3">
                <div class="group-payment">
                  <!-- <div class="nav-check" role="tablist">
                    <div class="nav-check__option">
                      <label class="labelRadio">{{'Ngân hàng liên kết' | translate}}
                        <input type="radio" id="optOne" [(ngModel)]="isNormal" [value]="false" name="intervaltype"
                          type="radio" data-target="#bank-connect">
                        <span class="checkmark"></span>
                      </label>
                    </div>
                    <div class="nav-check__option">
                      <label class="labelRadio">{{'Chuyển khoản ngân hàng' | translate}}
                        <input id="optTwo" [(ngModel)]="isNormal" name="intervaltype" [value]="true" type="radio"
                          data-target="#bank-deposit">
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div> -->
                  <div class="tab-content">
                    <div *ngIf="byWoori">
                      <div class="payment-method mb-3">
                        <div class="payment-qr">
                          <span class="payment-title">{{'Thanh toán qua ngân hàng liên kết' | translate}}</span>
                          <br />
                          <span class="payment-option">
                            <label for="">{{'Thanh toán qua Woori Bank' | translate}}</label>
                            <img src="../assets/images/icon/tick-active.png" alt="">
                          </span>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="byTnex">
                      <div class="payment-method mb-3">
                        <div class="payment-qr">
                          <span class="payment-title">{{'Thanh toán qua ngân hàng liên kết' | translate}}</span>
                          <br />
                          <span class="payment-option">
                            <label for="">{{'Thanh toán qua Tnex' | translate}}</label>
                            <img src="../assets/images/icon/tick-active.png" alt="">
                          </span>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="isNormal">
                      <div class="payment-method">
                        <div class="payment-qr">
                          <span class="d-block mb-2">{{'Thanh toán nhanh qua QR Code' | translate}}</span>
                          <a *ngIf="isDevice" class="guide-qr" (click)="openGuidePaymenmtQRPopup()" prevent-multi-clicks href="javascript: void(0)">
                            {{'Hướng dẫn thanh toán bằng QR Code' | translate}}</a>
                          <div class="info-notice" *ngIf="info.qrPaymentUrl && !isDevice">
                            <img src="./assets/images/icon/warning-org.svg" alt="">
                            <span>
                              <label>{{'Một số Ngân hàng chưa hỗ trợ quét mã QR. Quý khách vui lòng đối chiếu lại Thông
                                tin trước khi chuyển khoản.' | translate}}</label>
                            </span>
                          </div>
                          <div class="qr-guide">
                            <div class="row">
                              <div class="col-7 col-sm-5 col-lg-4">
                                <div class="box" style="--c:blue;--w:40px;--b:2px">
                                  <img [src]="info?.qrPaymentUrl" alt="">
                                </div>
                              </div>
                              <div class="col-sm-7 col-lg-8">
                                <label *ngIf="!isDevice">{{'Hướng dẫn thanh toán bằng QR Code' | translate}}</label>
                                <div id="collapseOne" [ngClass]="isDevice ? '' : 'show'" class="collapse"
                                  aria-labelledby="headingOne" data-parent="#accordion">
                                  <div class="card-body card-body_pc">
                                    <p><strong>1.</strong> {{'Mở ứng dụng ngân hàng (Mobile Banking) trên điện thoại của
                                      bạn và chọn chức năng QR Code' | translate}}</p>
                                    <p><strong>2.</strong> {{'Scan mã QR của lệnh mua CCQ' | translate}} </p>
                                    <p><strong>3.</strong> {{'Đối chiếu thông tin và thực hiện chuyển khoản' |
                                      translate}}</p>
                                  </div>
                                  <div class="card-body card-body_phone">
                                    <p><strong>1.</strong> {{'Nhấn vào “Tải về” để tải hình QR và lưu trên điện thoại
                                      của bạn' | translate}}</p>
                                    <p><strong>2.</strong> {{'Mở ứng dụng ngân hàng (Mobile Banking) của bạn và chọn
                                      chức năng QR Code' | translate}}</p>
                                    <p><strong>3.</strong> {{'Chọn hình QR đã tải về, đối chiếu thông tin và thực hiện
                                      chuyển khoản' | translate}}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="info-notice mb-3" *ngIf="info.qrPaymentUrl && isDevice">
                          <img src="./assets/images/icon/warning-org.svg" alt="">
                          <span>
                            <label>{{'Một số Ngân hàng chưa hỗ trợ quét mã QR.' | translate}}</label>
                            <label>{{'Quý khách vui lòng đối chiếu lại Thông tin trước khi chuyển khoản.' |
                              translate}}</label>
                          </span>
                        </div>
                        <div style="position: relative;">
                          <hr>
                          <span class="line-cut">{{'Hoặc' | translate}}</span>
                        </div>

                        <div class="col-12 col-sm-6 col-lg-12 mb-4">
                          <div class="row" style="text-align: left;">

                            <div class="form-group d-flex flex-column"
                              [ngClass]="isDevice ? 'col-12 fix-font' : 'col-6 '">
                              <label for=""><strong>{{'Ngân hàng thụ hưởng' | translate}}</strong></label>
                              <input type="text" class="form-control" [(ngModel)]="info.shortNameBank" #shortNameBank
                                readonly>
                              <div class="input-copys">
                                <button class="btn btn-copy" [class.btn-success]="isCopied1" type="button"
                                  [ngxClipboard]="shortNameBank" (cbOnSuccess)="copy('isCopied1')">{{'Copy' |
                                  translate}}</button>
                              </div>

                            </div>

                            <div class="form-group d-flex flex-column"
                              [ngClass]="isDevice ? 'col-12 fix-font' : 'col-6 '">
                              <label for=""><strong>{{'Số tài khoản thụ hưởng' | translate}}</strong></label>
                              <input [ngClass]="isDevice ? 'notice-font' : ''" type="text" class="form-control"
                                [(ngModel)]="info.number" #accNumber readonly>
                              <div class="input-copys">
                                <button class="btn btn-copy" [class.btn-success]="isCopied2" type="button"
                                  [ngxClipboard]="accNumber" (cbOnSuccess)="copy('isCopied2')">{{'Copy' | translate}}
                                </button>
                              </div>
                            </div>

                            <div class="form-group d-flex flex-column col-12 col-lg-12">
                              <label for=""><strong>{{'Tên tài khoản thụ hưởng' | translate}}</strong></label>
                              <input type="text" class="form-control" [(ngModel)]="info.receivePeople" #receivePeople
                                readonly>
                              <div class="input-copys">
                                <button class="btn btn-copy" [class.btn-success]="isCopied5" type="button"
                                  [ngxClipboard]="receivePeople" (cbOnSuccess)="copy('isCopied5')">{{'Copy' |
                                  translate}}
                                </button>
                              </div>
                            </div>

                            <div class="form-group d-flex flex-column col-12 col-lg-6">
                              <label for=""><strong>{{'Số tiền thanh toán' | translate}}</strong></label>
                              <input [ngClass]="isDevice ? 'notice-font' : ''" type="text" class="form-control"
                                [(ngModel)]="info.buyingValue" #accName readonly>
                              <!-- <div class="input-copys">
                                <button class="btn btn-copy" [class.btn-success]="isCopied5" type="button"
                                  [ngxClipboard]="receivePeople" (cbOnSuccess)="copy('isCopied5')">{{'Copy' |
                                  translate}}</button>
                              </div> -->
                            </div>

                            <div class="form-group d-flex flex-column col-12 col-lg-6">
                              <label for=""><strong>{{'Chi nhánh ngân hàng' | translate}}</strong></label>
                              <input type="text" class="form-control" [(ngModel)]="info.branch" #branch readonly>
                              <div class="input-copys">
                                <button class="btn btn-copy" [class.btn-success]="isCopied4" type="button"
                                  [ngxClipboard]="branch" (cbOnSuccess)="copy('isCopied4')">{{'Copy' | translate}}
                                </button>
                              </div>
                            </div>


                            <div class="form-group d-flex flex-column"
                              [ngClass]="isDevice ? 'col-12 fix-font' : 'col-12 '">
                              <label for=""><strong>{{'Chuyển khoản theo đúng nội dung sau:' |
                                  translate}}</strong></label>
                              <textarea [ngClass]="isDevice ? 'notice-font' : ''" class="disabled" type="text" id=""
                                rows="2"
                                style="width:100%; border-radius: 5px; color: orange; font-weight: bold; border: 1px solid orange !important"
                                #bankContent readonly> {{info.content}}</textarea>
                              <div class="input-copys">
                                <button class="btn btn-copy" [class.btn-success]="isCopied3" type="button"
                                  [ngxClipboard]="bankContent" (cbOnSuccess)="copy('isCopied3')">{{'Copy' | translate}}
                                </button>
                              </div>
                            </div>


                            <div class="flex-box">

                              <!-- <div class="info-notice info-notice__comment" *ngIf="isTransferNormal">
                                <img src="./assets/images/icon/warning-org.svg" alt="">
                                <span>
                                  <label>
                                    {{'Đối với ngân hàng thụ thưởng là HSBC và Standard Chartered Quý khách vui lòng
                                    chọn chuyển khoản thường.' |
                                    translate}}</label>
                                </span>
                              </div> -->
                              <div class="info-notice info-notice__comment" *ngIf="info.comment">
                                <img src="./assets/images/icon/warning-org.svg" alt="">
                                <span>
                                  <label>{{info.comment}}</label>
                                </span>
                              </div>
                            </div>

                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div *ngIf='!thirdAppId' class="col-lg-12 col-md-12 mb-3">
                <div class="group-payment">
                  <div class="tab-content">
                    <div class="payment-method">
                      <div class="payment-qr" *ngIf="!transaction?.isHideQr">
                        <span class="d-block mb-2">{{'Thanh toán nhanh qua QR Code' | translate}}</span>
                        <a *ngIf="isDevice" class="guide-qr" (click)="openGuidePaymenmtQRPopup()" prevent-multi-clicks href="javascript: void(0)">{{'Hướng
                          dẫn thanh toán bằng QR Code' | translate}}</a>
                        <div class="info-notice" *ngIf="info.qrPaymentUrl && !isDevice">
                          <img src="./assets/images/icon/warning-org.svg" alt="">
                          <span>
                            <label>{{'Một số Ngân hàng chưa hỗ trợ quét mã QR.' | translate}}</label>
                            <label>{{'Quý khách vui lòng đối chiếu lại Thông tin trước khi chuyển khoản.' |
                              translate}}</label>
                          </span>
                        </div>
                        <div class="qr-guide" >
                          <div class="row">
                            <div class="col-7 col-sm-5 col-lg-3 flexbox-all" >
                              <div class="d-flex flex-column">
                                <div class="box" style="--c:blue;--w:40px;--b:2px">
                                  <img [src]="info?.qrPaymentUrl" alt="">
                                </div>
                                <div class="reload-text" *ngIf="!isDevice">
                                  <span (click)="refresh()" prevent-multi-clicks>{{'Tải lại QR Code' | translate}}</span>
                                </div>
                                <div class="reload-text" (click)="refresh()" *ngIf="isDevice">
                                  <img src="./assets/images/icon/ic-reload.svg" alt="">
                                  <span>{{'Tải lại' | translate}}</span>
                                </div>
                              </div>

                              <!-- *ngIf="!info.qrPaymentUrl && info.status === 'ORDER_INIT'" -->

                            </div>
                            <div class="col-4 flexbox-all" *ngIf="isDevice">
                              <div class="qr-download" (click)="download()" prevent-multi-clicks>
                                <img src="./assets/images/icon/ic-download.svg" alt="">
                                <p>{{'Tải về' | translate}}</p>
                              </div>

                            </div>
                            <div class="col-sm-7 col-lg-8">
                              <label class="mb-3" *ngIf="!isDevice">{{'Hướng dẫn thanh toán bằng QR Code' |
                                translate}}</label>
                              <div id="collapseOne" [ngClass]="isDevice ? '' : 'show'" class="collapse"
                                aria-labelledby="headingOne" data-parent="#accordion">
                                <div class="card-body card-body_pc">
                                  <p><strong>1.</strong> {{'Mở ứng dụng ngân hàng (Mobile Banking) trên điện thoại của
                                    bạn và chọn chức năng QR Code' | translate}}</p>
                                  <p><strong>2.</strong> {{'Scan mã QR của lệnh mua CCQ' | translate}} </p>
                                  <p><strong>3.</strong> {{'Đối chiếu thông tin và thực hiện chuyển khoản' | translate}}
                                  </p>
                                </div>
                                <div class="card-body card-body_phone">
                                  <p><strong>1.</strong> {{'Nhấn vào “Tải về” để tải hình QR và lưu trên điện thoại của
                                    bạn' | translate}}</p>
                                  <p><strong>2.</strong> {{'Mở ứng dụng ngân hàng (Mobile Banking) của bạn và chọn chức
                                    năng QR Code' | translate}}</p>
                                  <p><strong>3.</strong> {{'Chọn hình QR đã tải về, đối chiếu thông tin và thực hiện
                                    chuyển khoản' | translate}}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="info-notice mb-3" *ngIf="info.qrPaymentUrl && isDevice">
                        <img src="./assets/images/icon/warning-org.svg" alt="">
                        <span>
                          <label>{{'Một số Ngân hàng chưa hỗ trợ quét mã QR.' | translate}}</label>
                          <label>{{'Quý khách vui lòng đối chiếu lại Thông tin trước khi chuyển
                            khoản.' | translate}}</label>
                        </span>
                      </div>
                      <div style="position: relative;" *ngIf="!transaction?.isHideQr">
                        <hr>
                        <span class="line-cut">{{'Hoặc' | translate}}</span>
                      </div>

                      <div class="col-12 col-sm-12 col-lg-12 mb-4">
                        <div class="row" style="text-align: left;">
                          <div class="form-group d-flex flex-column"
                            [ngClass]="isDevice ? 'col-12 fix-font' : 'col-6 '">
                            <label for=""><strong>{{'Ngân hàng thụ hưởng' | translate}}</strong></label>
                            <input type="text" class="form-control" [(ngModel)]="info.shortNameBank" #shortNameBank
                              readonly>
                            <div class="input-copys">
                              <button class="btn btn-copy" [class.btn-success]="isCopied1" type="button"
                                [ngxClipboard]="shortNameBank" (cbOnSuccess)="copy('isCopied1')">{{'Copy' | translate}}
                              </button>
                            </div>
                          </div>

                          <div class="form-group d-flex flex-column"
                            [ngClass]="isDevice ? 'col-12 fix-font' : 'col-6 '">
                            <label for=""><strong>{{'Số tài khoản thụ hưởng' | translate}}</strong></label>
                            <input [ngClass]="isDevice ? 'notice-font' : ''" type="text" class="form-control"
                              [(ngModel)]="info.number" #accNumber readonly>
                            <div class="input-copys">
                              <button class="btn btn-copy" [class.btn-success]="isCopied2" type="button"
                                [ngxClipboard]="accNumber" (cbOnSuccess)="copy('isCopied2')">{{'Copy' | translate}}
                              </button>
                            </div>
                          </div>

                          <div class="form-group d-flex flex-column col-12 col-lg-12">
                            <label for=""><strong>{{'Tên tài khoản thụ hưởng' | translate}}</strong></label>
                            <input type="text" class="form-control" [(ngModel)]="info.receivePeople" #receivePeople
                              readonly>
                            <div class="input-copys">
                              <button class="btn btn-copy" [class.btn-success]="isCopied5" type="button"
                                [ngxClipboard]="receivePeople" (cbOnSuccess)="copy('isCopied5')">{{'Copy' | translate}}
                              </button>
                            </div>
                          </div>

                          <div class="form-group d-flex flex-column col-12 col-lg-6">
                            <label for=""><strong>{{'Số tiền thanh toán' | translate}}</strong></label>
                            <input [ngClass]="isDevice ? 'notice-font' : ''" type="text" class="form-control"
                              [(ngModel)]="info.buyingValue" #accName readonly>
                            <!-- <div class="input-copys">
                              <button class="btn btn-copy" [class.btn-success]="isCopied5" type="button"
                                [ngxClipboard]="receivePeople" (cbOnSuccess)="copy('isCopied5')">{{'Copy' |
                                translate}}</button>
                            </div> -->
                          </div>

                          <div class="form-group d-flex flex-column col-12 col-lg-6">
                            <label for=""><strong>{{'Chi nhánh ngân hàng' | translate}}</strong></label>
                            <input type="text" class="form-control" [(ngModel)]="info.branch" #branch readonly>
                            <div class="input-copys">
                              <button class="btn btn-copy" [class.btn-success]="isCopied4" type="button"
                                [ngxClipboard]="branch" (cbOnSuccess)="copy('isCopied4')">{{'Copy' | translate}}
                              </button>
                            </div>
                          </div>


                          <div class="form-group d-flex flex-column"
                            [ngClass]="isDevice ? 'col-12 fix-font' : 'col-12 '">
                            <label for=""><strong>{{'Chuyển khoản theo đúng nội dung sau:' |
                                translate}}</strong></label>
                            <textarea [ngClass]="isDevice ? 'notice-font' : ''" class="disabled" type="text" id=""
                              rows="2"
                              style="width:100%; border-radius: 5px; color: orange; font-weight: bold; border: 1px solid orange !important"
                              #bankContent readonly> {{info.content}}</textarea>
                            <div class="input-copys">
                              <button class="btn btn-copy" [class.btn-success]="isCopied3" type="button"
                                [ngxClipboard]="bankContent" (cbOnSuccess)="copy('isCopied3')">{{'Copy' | translate}}
                              </button>
                            </div>
                          </div>


                          <div class="flex-box">
                            <!-- <div class="info-notice info-notice__comment" *ngIf="isTransferNormal">
                              <img src="./assets/images/icon/warning-org.svg" alt="">
                              <span>
                                <label>
                                  {{'Đối với ngân hàng thụ thưởng là HSBC và Standard Chartered Quý khách vui lòng chọn
                                  chuyển khoản thường.' |
                                  translate}}</label>
                              </span>
                            </div> -->
                            <div class="info-notice info-notice__comment" *ngIf="info.comment">
                              <img src="./assets/images/icon/warning-org.svg" alt="">
                              <span>
                                <label>{{info.comment}}</label>
                              </span>
                            </div>
                          </div>


                        </div>
                      </div>

                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div class="col-12 p-3 modal-footer" *ngIf="!isDevice && info?.status === Constant.ORDER_INIT">
              <div class="row w-100">
                <div class="col-12 col-lg-6" *ngIf="!thirdAppId">
                  <label *ngIf="!isDevice" for="" class="down note-payment">
                    {{'Chú ý' | translate}}:
                    <span style="color: #1f1f1f;">{{'Sau khi chuyển khoản xong, vui lòng' | translate}}
                      <strong class="required"> [{{(isNormal ? 'Xác nhận đã thanh toán' : 'Xác nhận thanh toán') |
                        translate}}]</strong>
                      {{'để hoàn tất đặt lệnh.' | translate}}
                    </span>
                  </label>
                </div>
                <div class="col-12 "
                  [ngClass]="!thirdAppId ? 'col-lg-6 d-flex justify-content-end' : 'd-flex justify-content-center'">
                  <!-- <button class="btn btn-close mr-4" (click)="dismiss()">{{'Đóng' | translate}}</button> -->
                  <button class="btn btn--blue btn--fix" (click)="confirm()" prevent-multi-clicks>
                    {{(isNormal ? 'Xác nhận đã thanh toán' :  'Xác nhận thanh toán') | translate}}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>