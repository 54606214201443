<div class="modal-content">
  <div class="modal-body">
    <div class="text-center">
      <div class="col-12 info-account text-center">
        <img src="./assets/images/robo/robo-success.png">
        <h4 class="popup-message">Chức năng này chỉ sử dụng trên App</h4>

        <div class="group_qr" *ngIf="!isMobile">
          <span class="group_qr-img">
            <img src="./assets/images/homepage/qr-download.png" alt="down app">
          </span>
          <div class="group_qr-title">
            <label>Quét để tải ứng dụng</label>
            <span>iOS & Android</span>
            <a [href]="introduce" target="_blank" *ngIf="introduce">
              Giới thiệu
            </a>
          </div>
        </div>

        <div class="group_btn" *ngIf="isMobile">
          <div class="btn-app">
            <a [href]="Constant.IOS_APP" title="Fmarket Appstore " target="_blank">
              <img src="./assets/images/homepage/footer-app.svg" alt="appstore-fmarket">
            </a>
            <a [href]="Constant.ANROID_APP" title="Fmarket CH Play"
              target="_blank">
              <img src="./assets/images/homepage/footer-google.svg" alt="chplay-fmarket">
            </a>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>